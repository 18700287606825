import React, { useState } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import Button from './Button';

interface LocationModalProps {
  isOpen: boolean;
  onClose: () => void;
  locationList: Array<{
    location_id: number;
    postal_code: string;
    location: string;
  }>;
  onSubmit: (selectedLocation: any) => void;
  t: (key: string) => string;
}

const LocationModal: React.FC<LocationModalProps> = ({
  isOpen,
  onClose,
  locationList,
  onSubmit,
  t,
}) => {
  const [selectedLocationId, setSelectedLocationId] = useState<number | null>(null);
  const [searchQuery, setSearchQuery] = useState('');

  const handleLocationSelect = (location: any) => {
    setSelectedLocationId(location.location_id);
    // onSubmit(location);
    // onClose();
  };

  const handleSubmit = () => {
    const selectedLocation = locationList.find(loc => loc.location_id === selectedLocationId);
    if (selectedLocation) {
      onSubmit(selectedLocation);
      onClose();
    }
  };

  const filteredLocations = locationList.filter(location =>
    `${location.postal_code} ${location.location}`.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{
        content: {
          height: 445,
          width: 'auto',
          margin: 'auto',
          padding: 10,
        },
      }}
    >
      <div className="flex flex-col h-full">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h5 className="text-lg font-medium">{t('select_location')}</h5>
          <FontAwesomeIcon
            icon={faTimes}
            className="text-xl cursor-pointer text-emerald-700"
            onClick={onClose}
          />
        </div>

        <input
          type="text"
          placeholder={t('search_locations')}
          className="w-full p-2 mb-3 border rounded"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{ width: '100%' }}
        />

        <div className="flex-1 overflow-y-auto">
          <ul className="divide-y divide-gray-200" style={{ height: "242px" }}>
            {filteredLocations.map((location) => (
              <li
                key={location.location_id}
                className={`d-flex justify-content-between align-items-center ${selectedLocationId === location.location_id ? 'bg-emerald-50' : ''
                  }`}
                onClick={() => handleLocationSelect(location)}
                style={{ cursor: 'pointer', padding: '10px', border: '1px solid #ccc' }}
              >
                <span className="flex-1">
                  {location.location}
                </span>
                {selectedLocationId === location.location_id && (
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="text-emerald-600"
                  />
                )}
              </li>
            ))}
          </ul>
        </div>

        <div className="d-flex align-items-center justify-content-center mt-4">
          <Button
            color="white"
            onButtonClick={handleSubmit}
            title={t('Submit')}
            width="110px"
            backgroundColor={selectedLocationId ? "green" : "gray"}
            border={selectedLocationId ? "2px solid green" : "2px solid gray"}
            disabled={!selectedLocationId}
          />
        </div>
      </div>
    </Modal>
  );
};

export default LocationModal;
