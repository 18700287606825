import {
  faList, faPlus,
  faUser
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Drawer, List, ListItem, ListItemText } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import useLogout from '../hooks/useLogout'
import { AuthDetails } from '../libs/authDetails'

const Sidebar = ({
  isOpen,
  onClose,
  isAdmin
}: {
  isOpen: boolean
  onClose?: () => void
  isAdmin?: boolean
}) => {
  const navigate = useNavigate()
  const { logout } = useLogout()
  const { t } = useTranslation()
  const { user }  = AuthDetails()
  const handeForm = () => {
    navigate('/form', { state: { showModal: true } });
  }
  return (
    <Drawer open={isOpen} onClose={onClose} className="sideBar">
      <h1 className="sidebar-heading mt-5 mb-1 welcomeText">{t('Menu')}</h1>
      <h4 className="sidebar-heading mt-1"> {user?.user?.firstname} {user?.user?.lastname}</h4>
      <List className="sidebar-list">
        <ListItem
          button
          onClick={handeForm}
          className="sidebar-item">
          <div className="d-flex align-items-center  ml-3">
            <FontAwesomeIcon icon={faPlus} className="sidebar-icon" />
            <ListItemText primary={t('AddTrip')} />
          </div>
        </ListItem>

        {isAdmin && (
          <>
            <ListItem
              button
              onClick={() => navigate('/admin')}
              className="sidebar-item">
              <div
                className="d-flex align-items-center  ml-3"
                onClick={() => navigate('/admin')}>
                <FontAwesomeIcon icon={faUser} className="sidebar-icon" />
                <ListItemText primary={t('ManageUser')} />
              </div>
            </ListItem>
            <ListItem
              button
              onClick={() => navigate('/userList')}
              className="sidebar-item">
              <div className="d-flex align-items-center  ml-3">
                <FontAwesomeIcon icon={faList} className="sidebar-icon" />
                <ListItemText primary={t('Trip_List')} />
              </div>
            </ListItem>
          </>
        )}
      </List>
      <button onClick={logout} className="buttonLogot">
        {t('Logout')}
      </button>
      </Drawer>
  )
}

export default Sidebar
