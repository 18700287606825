import React, { useState, useEffect } from 'react'
import { getTripDetails } from '../services/ApiServices'
import { AuthDetails } from '../libs/authDetails'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import Icon from '../assets/imagesPng/userIcon.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FaFileSignature } from 'react-icons/fa6'
import Header from '../components/Header'
import Loader from 'react-js-loader'
import { TripDetailsData } from '../types/userList'
import AuthChecker from '../libs/AuthChecker'
interface PauseTime {
  end_time: string;
  start_time: string;
}
const TripDetails = () => {
  const [tripDetails, setTripDetails] = useState<TripDetailsData | null | undefined>(null);

  const navigation = useNavigate()
  const [userIcon, setUserIcon] = useState()
  const location = useLocation()
  console.log("tripDetails", tripDetails)

  const tripId = location.state?.tripId
  const { t } = useTranslation()
  const [isload, setIsLoad] = useState(true);
  const { token } = AuthDetails()
  const [pauseTimes, setPauseTimes] = useState<PauseTime[]>([])
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [])
  useEffect(() => {
    if (tripId) {
      fetchTripDetails(tripId)
    }
  }, [tripId])

  console.log("fbhjbv", tripDetails)
  const fetchTripDetails = async (tripId: string) => {
    try {
      const response = await getTripDetails(tripId, token)
      const parsedPauseTimes = JSON.parse(response.pauseTime || '[]')
      setTripDetails(response)
      setUserIcon(response.profile_picture)
      setPauseTimes(parsedPauseTimes)
      setIsLoad(false)
    } catch (error) {
      console.error('Error fetching trip details:', error)
    }
  }
  AuthChecker()
  const formatDate = (dateTimeString: string | number | Date) => {
    const date = new Date(dateTimeString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    let hours: any = date.getUTCHours();
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');

    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? String(hours).padStart(2, '0') : '12';

    const formattedDate = `${day}/${month}/${year} at ${hours}:${minutes} ${ampm}`;
    return formattedDate;
  };
  const renderPauseTimes = () => {
    return pauseTimes.map((pauseTime: PauseTime, index: number) => (
      <div
        key={index}
        className="d-flex align-items-center justify-content-center flex-row inputbox mt-3">
        <div className="inputWidth">
          <h6 className="inputHeading">{t('PauseFrom')}</h6>
          <input
            type="time"
            className="inputform"
            style={{ width: '100%', background: 'white' }}
            value={pauseTime.start_time}
            readOnly
          />
        </div>
        <div className="inputWidth">
          <h6 className="inputHeading">{t('PauseTo')}</h6>
          <input
            type="time"
            className="inputform"
            style={{ width: '100%', background: 'white' }}
            value={pauseTime.end_time}
            readOnly
          />
        </div>
      </div>
    ))
  }

  return (
    <>

      <Header style={{ paddingTop: "16px", paddingBottom: "16px" }} />
      {isload ?
        <div className="d-flex align-items-center justify-content-center clock"
          style={{ height: "100%", backdropFilter: "blur(1px)" }}>
          <Loader
            type="bubble-top"
            bgColor={'#05653F'}
            color={'#05653F'}
            size={50}
          /></div> : null}
      <div className="main-container mt-4">
        <form >
          <div style={{ position: 'relative', padding: '10px' }}>
            <FontAwesomeIcon
              icon={faTimes}
              color="black"
              onClick={() => navigation('/dashboard')}
              className="crossIcon"
            />
            <div
              className="yellow-ribbon-detail"
              style={{
                backgroundColor: 'yellowgreen'
              }}></div>
            <h4 style={{ textAlign: 'center' }} className="mb-2">
              {t('TripDetails')}
            </h4>
            <div className="d-flex align-items-center justify-content-center ">
              {userIcon ? (
                <div
                  className="d-flex align-items-center flex-column justify-content-space-evenly"
                  style={{ gap: '5px' }}>
                  <img
                    src={tripDetails?.profile_picture ?? ''}
                    alt="User Icon"
                    width={50}
                    height={50}
                    style={{ borderRadius: '50px' }}
                  />
                </div>
              ) : (
                <img
                  src={Icon}
                  alt=""
                  width={50}
                  height={50}
                  style={{ borderRadius: '50px' }}
                />
              )}
            </div>
            <div className="d-flex  justify-content-center flex-column  mt-1">
              <h6 className="">{t('startTime')}</h6>
              <input
                type="text"
                id="location"
                className="inputform"
                value={tripDetails ? formatDate(tripDetails.start_time) : ''}
                readOnly
                required
                style={{ width: '100%' }}
              />
            </div>
            {renderPauseTimes()}
            {tripDetails?.category === "Driver" ?
              <div className="d-flex  justify-content-center flex-row inputbox mt-3 mb-2">
                <div className="inputWidthNoH">
                  <h6 className="">{t('Location_Subtown')}</h6>
                  <input
                    type="text"
                    id="location"
                    className="inputform"
                    value={tripDetails ? tripDetails.location : ''}
                    readOnly
                    required
                    style={{ width: '100%' }}
                  />
                </div>
                <div className="inputWidthNoH">
                  <h6 className="">{t('postal_code')}</h6>
                  <input
                    className="inputform"
                    id="serviceDropdown"
                    name="serviceType"
                    value={tripDetails ? tripDetails.postal_code : ''}
                    style={{ width: '100%' }}
                    readOnly
                    required
                  />
                </div>
              </div> : null}
            {/* {tripDetails?.category === "Machine Operator"? null :
              <div className="d-flex  justify-content-center flex-column" style={{ marginTop: tripDetails?.category !== "Driver" ? "12px" : "" }}>
                <h6 className="">{t('garbageType')}</h6>
                <input
                  type="text"
                  className="inputform"
                  id="garbage_type"
                  value={tripDetails ? tripDetails.garbage_types : ''}
                  readOnly
                  required
                  style={{ width: '100%' }}
                />
              </div>} */}


            {tripDetails?.category === "Machine Operator" ?
              <div className="d-flex  justify-content-center flex-column  mt-3 ">
                <h6 className="">{t('Category')}</h6>
                <input
                  type="text"
                  className="inputform"
                  id="garbage_type"
                  value={tripDetails ? tripDetails.category : ''}
                  readOnly
                  required
                  style={{ width: '100%' }}
                />
              </div> : <div className="d-flex  justify-content-center flex-row inputbox mt-3 ">
                <div className="inputWidthNoH">
                  <h6 className="">{t('Category')}</h6>
                  <input
                    type="text"
                    className="inputform"
                    id="garbage_type"
                    value={tripDetails ? tripDetails.category : ''}
                    readOnly
                    required
                    style={{ width: '100%' }}
                  />
                </div>
                {tripDetails?.category === 'Yard Worker' || tripDetails?.category === 'Workshop Worker' || tripDetails?.category === 'Locksmith' ? null : <div className="inputWidthNoH">
                  <h6 className="">{t('VehicleNo')}</h6>
                  <input
                    className="inputform"
                    id="serviceDropdown"
                    name="serviceType"
                    value={tripDetails ? tripDetails.vehicle_number : ''}
                    style={{ width: '100%' }}
                    readOnly
                    required
                  />
                </div>}
              </div>}
            {/* <div className="d-flex align-items-center justify-content-center flex-row inputbox mt-3">
              <div className="inputWidth">
                          <div className="d-flex  justify-content-center flex-column  mt-3">
                <h6 className="">{t('KmTotal')}</h6>
                <input
                  type="number"
                  placeholder="km"
                  className="inputform"
                  style={{ width: '100%' }}
                  value={tripDetails ? tripDetails.kmtotal : ''}
                  readOnly
                  required
                />
              </div>
              <div className="inputWidth">
                <h6 className={''}>{t('totalWeight')}</h6>
                <input
                  type="number"
                  placeholder={t('weight')}
                  className="inputform"
                  style={{ width: '100%' }}
                  value={tripDetails ? tripDetails.total_weight : ''}
                  required
                  readOnly
                />
              </div>
            </div> */}
            {tripDetails?.category === "Driver" ? <div className="d-flex align-items-center justify-content-center flex-row inputbox mt-3 mb-2">
              <div className="w-100">
                <h6 className="">{t('startMileage')}</h6>
                <input
                  type="number"

                  className="inputform"
                  style={{ width: '100%' }}
                  value={tripDetails?.start_mileage ?? ''}
                  readOnly
                  required
                />
              </div>
              {/* <div className="inputWidthNoH">
                <h6 className={''}>{t('endMileage')}</h6>
                <input
                  type="number"
                  className="inputform"
                  style={{ width: '100%' }}
                  value={tripDetails?.final_mileage ?? ''}
                  required
                  readOnly
                />
              </div> */}
            </div> : null}
            <div className="d-flex align-items-center justify-content-center ">
              {tripDetails && tripDetails.signature ? (
                <img
                  src={tripDetails.signature}
                  alt="User Icon"
                  width={100}
                  height={100}
                />
              ) : (
                <FaFileSignature width={100} height={100} />
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default TripDetails
