import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface StepPassengerNameProps {
  PassengerName?: string;
  handleChangeName?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errorName?: string;
  onNext: () => void;
  nameDisabled?: boolean;
  mileage: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errorMileage?: string;
  mileageDisabled?: boolean;
  istQuestion?: string;
  onPrevious?: () => void;
  handleKeyPress?:any
  operatingHours?:any
  handleChangeHours?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isOperatingHours?:any
  onNextHours?:any
  errorOperating?:any
  selectedDriver?:any

}

const StepPassengerName: React.FC<StepPassengerNameProps> = ({
  PassengerName,
  handleChangeName,
  handleKeyPress,
  errorName,
  onNext,
  nameDisabled,
  mileage,
  handleChange,
  errorMileage,
  mileageDisabled,
  istQuestion,
  onPrevious,
  operatingHours,selectedDriver,
  handleChangeHours,isOperatingHours,onNextHours,errorOperating

}) => {
  const { t } = useTranslation();
  const [isMileage, setIsMileage] = useState(false);



  return (
    <div className="d-flex align-items-center justify-content-center m-auto height">
      <form className="main-container" onSubmit={handleKeyPress}>
       {!isOperatingHours?
       <><div className="userHeading" style={{background: "#F6821F"}}>
          <h2 className="textcolor">
          {   t("Mileage") }
          </h2>
        </div>
        <div className='p-3'>
          {/* {isMileage ? ( */}
            <>
              {/* <h6>{t('Mileage')}</h6> */}
              <input
                type="number"
                placeholder={t('finalMileage')}
                className="inputform w-100 mt-4"
                value={mileage}
              onChange={handleChange}
              onKeyDown={handleKeyPress}
              />
              {errorMileage && <span className="error-message">{errorMileage}</span>}
            </>
          {/* ) : ( */}
            {/* <>
              <label>{t('Passenger_name')}</label>
              <input
                type="text"
                className="inputform w-100"
                placeholder={t('Passenger_name')}
                value={PassengerName}
                onChange={handleChangeName}

              />
              {errorName && <span className="error-message">{errorName}</span>}
            </> */}
          {/* )} */}

          <div className="d-flex align-items-center justify-content-center mb-3 mt-4">
          <FontAwesomeIcon
            icon={faChevronCircleRight}
            color="#F6821F"
            className="backIcon"
            onClick={onNext}
          />
          <h6 className="" onClick={onNext} style={{ cursor: 'pointer', color: '#F6821F', fontSize:'20px' , marginLeft:'10px'}} >
            {t("further")}
          </h6>
        </div>
        </div></>:<><div className="userHeading" style={{background: "#F6821F"}}>
          <h2 className="textcolor">
          {   t("OperatingHours") }
          </h2>
        </div>
        <div className='p-3'>

            <>
              <h6>{t('OperateHour')}</h6>
              <input
                type="number"
                placeholder={t('')}
                className="inputform w-100"
                value={operatingHours}
              onChange={handleChangeHours}
              onKeyDown={handleKeyPress}
              />
              {errorOperating && <span className="error-message">{errorOperating}</span>}
            </>

            <div className="d-flex align-items-center justify-content-center mb-3 mt-4">
          <FontAwesomeIcon
            icon={faChevronCircleRight}
            color="#F6821F"
            className="backIcon"
            onClick={onNextHours}
          />
          <h6 className="" onClick={onNextHours} style={{ cursor: 'pointer', color: '#F6821F', fontSize:'20px'}} >
            {t("further")}
          </h6>
        </div>
        </div></>}
      </form>
    </div>
  );
};

export default StepPassengerName;
