import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import trip_logo from '../assets/imagesPng/trip-logo.png'
import { useNavigate } from 'react-router-dom'

const Header = ({ title ,style}: { title?: string , style?:React.CSSProperties}) => {
  const navigate = useNavigate()
  const handleBackClick = () => {
    navigate('/dashboard')
    localStorage.removeItem('selectedDriver')

}
  return (
    <div className="main_header" style={style}>

        <FontAwesomeIcon
          icon={faAngleLeft}
          className="iconCheck"
          color="white"
          onClick={handleBackClick}
        />

    <div className='textHead'>
    <h1 className="text-white headerTitile">{title}</h1>
     </div>
       <div className="image-container ">
          <img src={trip_logo} alt="Trip Logo" height={'30px'} width='200px'/>
        </div>
    </div>
  )
}

export default Header
