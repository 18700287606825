import {
  faChevronCircleLeft,
  faChevronCircleRight
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import 'moment/locale/de';
import React, { useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from 'react-i18next';
import de from 'date-fns/locale/de';

interface FormData {
  start_time: string
  end_time: string
  driver: string
  car_number: string
  pauseTime: string
  kmTotal: string
  total_time: string
  total_weight: string
  total_current_day: string
  cost_center_id: string
  current_time: string
}
interface StartAndPauseProps {
  onNext: (data: any) => void;
  onPrevious: () => void;
  initialFormData: any;
  isStartTime: boolean;
  data: any;
}
const StartAndPause: React.FC<StartAndPauseProps> = ({
  onNext,
  onPrevious,
  initialFormData,
  isStartTime,
}) => {
  const { t } = useTranslation()
  registerLocale('de', de);

  const [currentDate] = useState(
    new Date().toISOString().split('T')[0]
  )
  const [timeError, setTimeError] = useState('')
  const [validationErrors, setValidationErrors] = useState({
    start_time: '',
    end_time: '',
    pauseTime: '',
    pauseFrom: '',
    pauseTo: ''
  })

  const {
    start_time: initialStartTime,
    end_time: initialEndTime,
    pauseTime: initialPauseTime,
    current_time: initialCurrentTime
  } = initialFormData || {}

  const [formData, setFormData] = useState(() => {
    const storedFormData = localStorage.getItem('startandPauseData')
    const now = moment().format('YYYY-MM-DD')
    const time = moment().format('HH:mm')
    return storedFormData
      ? JSON.parse(storedFormData)
      : {
        start_time: initialStartTime || now,
        end_time: initialEndTime || '',
        pauseTime: initialPauseTime,
        current_time: initialCurrentTime || time
      }
  })
  const today = new Date().toISOString().split('T')[0];
  // const currentDate = today;
  const handleSubmit = (e?: { preventDefault: () => void }) => {
    if (e) {
      e.preventDefault()
    }
    const hasErrors = Object.values(validationErrors.start_time).some(
      (error) => error !== ''
    )
    if (!formData.start_time) {
      setValidationErrors({
        ...validationErrors,
        start_time: t('Please_start_time')
      })
      return
    }

    if (!hasErrors) {
      onNext(formData)
    }
  }

  useEffect(() => {
    localStorage.setItem('startandPauseData', JSON.stringify(formData))
  }, [formData])

  // const handleChange = (
  //   e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  //   field: keyof FormData
  // ) => {
  //   const value = e.target.value
  //   setFormData({ ...formData, [field]: value })

  //   if (field === 'start_time') {
  //     const selectedDate = new Date(value).toISOString().split('T')[0]
  //     if (selectedDate < currentDate) {
  //       setValidationErrors({
  //         ...validationErrors,
  //         start_time: t('previous_date')
  //       })
  //     } else {
  //       setValidationErrors({ ...validationErrors, start_time: '' })
  //     }
  //   }
  // }

  const handleDateChange = (date: Date | null) => {
    if (!date) return;

    moment.locale('de');
    const selectedDate = moment(date).format('YYYY-MM-DD');

    setFormData({ ...formData, start_time: selectedDate });

    if (selectedDate < moment().format('YYYY-MM-DD')) {
      setValidationErrors({ ...validationErrors, start_time: t('previous_date') });
    } else {
      setValidationErrors({ ...validationErrors, start_time: '' });
    }
  };
  // registerLocale('de', de);
  const [minTime, setMinTime] = useState('')

  useEffect(() => {
    const updateMinTime = () => {
      const now = moment().format('HH:mm')
      setMinTime(now)
    }

    updateMinTime()
    const interval = setInterval(updateMinTime, 1000)

    return () => clearInterval(interval)
  }, [])
  const handleChangeTime = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: string
  ) => {
    setTimeError('')
    const selectedTime = e.target.value
    const currentDate = moment().format('YYYY-MM-DD');
    const startTimeDate = moment(formData.start_time);
    if ((moment(selectedTime, 'HH:mm').isSameOrAfter(moment(minTime, 'HH:mm'))) || startTimeDate.isAfter(currentDate)) {
      setFormData({ ...formData, [key]: selectedTime })
    } else {
      setTimeError(t('Time_Current_not'))
    }
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSubmit()
    }
  }

  return (
    <div className="d-flex align-items-center justify-content-center m-auto height">
      <form className="main-container scrollable-container">

        {isStartTime ? (
          <> <div className="userHeading">
            <h5 className="textcolor">{t('time')}</h5>
          </div>
            {/* <WeekDayPicker /> */}
            <div className="p-3">
              <div className="d-flex flex-row  mt-1 mb-2 flex-column">
                <label className="textFont">{t('startTime')}</label>
                {/* <input
                  type="date"
                  className="inputform "
                  style={{ width: '100%', background: 'white' }}
                  value={formData.start_time}
                  onChange={(e) => handleChange(e, 'start_time')}
                  onKeyDown={handleKeyPress}
                  min={today}
                /> */}
                <DatePicker
                  selected={formData.start_time ? moment(formData.start_time).toDate() : null}
                  onChange={(date: any) => handleDateChange(date)}
                  dateFormat="EEEE, d MMMM yyyy"
                  locale="de"
                  minDate={new Date()}
                  className="inputform w-100"
                  calendarStartDay={1}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  // readOnly
                />
                {validationErrors.start_time && (
                  <span className="error-message mb-2">
                    {validationErrors.start_time}
                  </span>
                )}
              </div>
            </div>
          </>
        ) : (
          <> <div className="userHeading">
            <h5 className="textcolor ">{t('time2')} <span style={{ fontWeight: '900', textDecoration: 'underline' }}>
              {t('time22')}</span>
              {t('time222')}</h5>
          </div>
            <div className="p-3">
              <div style={{ height: '80px' }}>
                <div className="d-flex flex-row  mt-1 flex-column">
                  <label className="textFont">{t('Cur_time')}</label>
                  <input
                    type="time"
                    className="inputform "
                    style={{ width: '100%', background: 'white' }}
                    value={formData.current_time}
                    onChange={(e) => handleChangeTime(e, 'current_time')}
                    onKeyDown={handleKeyPress}
                    min={minTime}
                  />
                </div>
                {timeError && <div className="text-danger">{timeError}</div>}
              </div>
            </div></>
        )}

        <div
          className="d-flex align-items-center justify-content-center  mb-3 " style={{ gap: 50 }}>
          <div className="d-flex justify-content-center flex-row  align-items-center gap-1" >
            <h6 className="" onClick={() => onPrevious()} style={{ cursor: 'pointer', color: '#05653F', fontSize: '20px' }} >
              {t("back")}
            </h6>
            <FontAwesomeIcon
              icon={faChevronCircleLeft}
              color="#05653F"
              className=" backIcon"
              onClick={() => onPrevious()}
            />

          </div>
          <div className="d-flex justify-content-center flex-row  align-items-center gap-1" >
            <FontAwesomeIcon
              icon={faChevronCircleRight}
              color="#05653F"
              className="backIcon"
              onClick={handleSubmit}
            />
            <h6 className="" onClick={handleSubmit} style={{ cursor: 'pointer', color: '#05653F', fontSize: '20px' }} >
              {t("further")}
            </h6>
          </div>
        </div>
      </form>
    </div>
  )
}

export default StartAndPause
