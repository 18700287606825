import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../components/Button'
import SelectImage from '../components/SelectImage'
import useFileUpload from '../hooks/useFileUpload'
import {
  getTripLicenseDetails,
  getTripDriverCardDetails,
  getTripFQNCardDetails
} from '../services/ApiServices'
import { AuthDetails } from '../libs/authDetails'
import { useQuery } from 'react-query'
import {
  faChevronCircleLeft,
  faChevronCircleRight
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Loader from 'react-js-loader'
import AuthChecker from '../libs/AuthChecker'

interface LicenseFormProps {
  data: any
  onNext: (data: any) => void
  onPrevious: () => void
  licenseForm: boolean
  driverCard?: boolean
  fqnCard?: boolean
  loading: (value: boolean) => void
}

const LicenseForm = ({
  data,
  onNext,
  onPrevious,
  licenseForm,
  driverCard,
  fqnCard
}: LicenseFormProps) => {
  const [selectedImagePath, setSelectedImagePath] = useState<string | null>(
    data?.licenseImage || null
  )
  const [isLoader, setIsLoading] = useState(false)
  const [imageUploaded, setImageUploaded] = useState(false)
  const [selectedCardImagePath, setSelectedCardImagePath] = useState<string | null>(data?.driverCard || null)

  const [cardExpiryDate, setcardExpiryDate] = useState<string>(
    data?.cardExpiryDate || ''
  )
  const [selectedFQNImagePath, setSelectedFQNImagePath] = useState<string | null>(data?.fqnCard || null)
  const [licenseFQNExpiryDate, setLicenseFQNExpiryDate] = useState<string>(
    data?.fqnExpirationDate || ''
  )
  const [initialFQNLicenseDate, setinitialFQNLicenseDate] = useState<
    string | null
  >(data?.fqnCard || null)
  const [initialFQNImagePath, setinitialFQNImagePath] = useState<string | null>(
    data?.fqnExpirationDate || ''
  )
  const [initialCardDate, setinitialCardDate] = useState<string | null>(
    data?.cardExpiryDate || null
  )
  const [initialCardImagePath, setinitialCardImagePath] = useState<
    string | null
  >(data?.driverCard || '')

  const data11 = JSON.parse(
    localStorage.getItem('tripAndVehicleTypeData') || '{}'
  )
  const [licenseExpiryDate, setLicenseExpiryDate] = useState<string>(
    data?.licenseExpiry || ''
  )
  const [isShowModal, setIsShowModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [errorMessageInput, setErrorMessageInput] = useState<string>('')
  const [errorMessageInput1, setErrorMessageInput1] = useState<string>('')
  const [errorMessageInput2, setErrorMessageInput2] = useState<string>('')

  const [errorMessageImage, setErrorMessageImage] = useState<string>('')
  const [initialLicenseDate, setInitialLicenseDate] = useState<string | null>(
    data?.licenseImage || null
  )
  const [initialImagePath, setInitialImagePath] = useState<string | null>(
    data?.licenseExpiry || ''
  )

  const { token, is_admin, userId: authUserId, username } = AuthDetails()
  const { t } = useTranslation()
  const { UploadFile } = useFileUpload()
  const UserId = is_admin === 1 ? data11.user_id : authUserId
  const today = new Date().toISOString().split('T')[0];

  const fetchTripCardDetails = async () => {
    try {
      const data = await getTripDriverCardDetails(UserId, token)

      return data
    } catch (error) {
      console.error('Error fetching trip license details:', error)
      throw new Error('Error fetching trip license details')
    }
  }
  const { data: CardDetails = {} } = useQuery(
    'getTripCardDetails',
    fetchTripCardDetails
  )
  useEffect(() => {
    if (CardDetails && CardDetails.cardExpirationDate) {
      const currentDate = new Date().toISOString().split('T')[0]
      const cardDate = CardDetails.cardExpirationDate

      const cardImage = CardDetails.driverCard

      if (cardDate > currentDate) {
        setcardExpiryDate(cardDate)
        setSelectedCardImagePath(cardImage)
        setinitialCardDate(cardDate)
        setinitialCardImagePath(cardImage)
      } else {
        /*empty*/
      }
    }
  }, [CardDetails])
  useEffect(() => {
    if (isShowModal) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isShowModal])

  const fetchTripLicenseDetails = async () => {
    try {
      const data = await getTripLicenseDetails(UserId, token)
      return data
    } catch (error) {
      console.error('Error fetching trip license details:', error)
      throw new Error('Error fetching trip license details')
    }
  }

  const { data: LicenseDetails = {} } = useQuery(
    'getTripLicenseDetails',
    fetchTripLicenseDetails
  )
  useEffect(() => {
    if (LicenseDetails && LicenseDetails.license_expiration_date) {
      const currentDate = new Date().toISOString().split('T')[0]
      const licenseDate = LicenseDetails.license_expiration_date

      const licenseImage = LicenseDetails.license_picture
      if (licenseDate > currentDate) {
        setLicenseExpiryDate(licenseDate)
        setSelectedImagePath(licenseImage)
        setInitialLicenseDate(licenseDate)
        setInitialImagePath(licenseImage)
      } else {
        /*empty*/
      }
    }
  }, [LicenseDetails])
  const handleImageUri = async (path: string) => {
    setIsLoading(true);

    try {
      const blob = await fetch(path ?? '').then((res) => res.blob());
      const timestamp = new Date().getTime();

      if (licenseForm) {
        const filename = `license_${username}_${timestamp}`;
        const downloadURLLicense = await UploadFile(blob, filename);
        setSelectedImagePath(downloadURLLicense as string);
        setImageUploaded(true);
      }

      if (driverCard) {
        const filename = `card_${username}_${timestamp}`;
        const downloadURLDriverCard = await UploadFile(blob, filename);
        setSelectedCardImagePath(downloadURLDriverCard as string);
        setImageUploaded(true);
      }

      if (fqnCard) {
        const filename = `fqn_${username}_${timestamp}`;
        const downloadURLFqnCard = await UploadFile(blob, filename);
        setSelectedFQNImagePath(downloadURLFqnCard as string);
        setImageUploaded(true);
      }
    } catch (error) {
      console.error('Error uploading image:', error);
    } finally {
      setIsLoading(false);
      setIsShowModal(false);
      setErrorMessage('');
      setErrorMessageImage('');
    }
  };


  const handleLicense = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = event.target.value;

    if (new Date(selectedDate) < new Date(today)) {
      const errorMsg = t("lincense_error1");
      setErrorMessageInput(errorMsg);
      localStorage.setItem('errorMessageInput', errorMsg);
    } else {
      setErrorMessageInput("");
      localStorage.removeItem('errorMessageInput');
      localStorage.setItem('licenseExpiryDate', selectedDate); // Save to local storage
    }
    setLicenseExpiryDate(selectedDate);
  };

  const handleDriver = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = event.target.value;

    if (new Date(selectedDate) < new Date(today)) {
      const errorMsg = t("lincense_error2");
      setErrorMessageInput1(errorMsg);
      localStorage.setItem('errorMessageInput1', errorMsg);
    } else {
      setErrorMessageInput1("");
      localStorage.removeItem('errorMessageInput1');
      localStorage.setItem('cardExpiryDate', selectedDate); // Save to local storage
    }
    setcardExpiryDate(selectedDate);
  };

  const handleFqn = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = event.target.value;

    if (new Date(selectedDate) < new Date(today)) {
      const errorMsg = t("lincense_error3");
      setErrorMessageInput2(errorMsg);
      localStorage.setItem('errorMessageInput2', errorMsg);
    } else {
      setErrorMessageInput2("");
      localStorage.removeItem('errorMessageInput2');
      localStorage.setItem('licenseFQNExpiryDate', selectedDate); // Save to local storage
    }
    setLicenseFQNExpiryDate(selectedDate);
  };

  useEffect(() => {
    const storedErrorMessage = localStorage.getItem('errorMessageInput');
    if (storedErrorMessage) {
      setErrorMessageInput(storedErrorMessage);
    }
    const storedErrorMessage1 = localStorage.getItem('errorMessageInput1');
    if (storedErrorMessage1) {
      setErrorMessageInput1(storedErrorMessage1);
    } const storedErrorMessage2 = localStorage.getItem('errorMessageInpu2');
    if (storedErrorMessage2) {
      setErrorMessageInput2(storedErrorMessage2);
    }
    const storedLicenseExpiry = localStorage.getItem('licenseExpiryDate');
    const storedCardExpiry = localStorage.getItem('cardExpiryDate');
    const storedFqnExpiry = localStorage.getItem('licenseFQNExpiryDate');

    if (storedLicenseExpiry) {
      setLicenseExpiryDate(storedLicenseExpiry);
    }

    if (storedCardExpiry) {
      setcardExpiryDate(storedCardExpiry);
    }

    if (storedFqnExpiry) {
      setLicenseFQNExpiryDate(storedFqnExpiry);
    }
  }, []);

  const fetchTripFQNDetails = async () => {
    try {
      const data = await getTripFQNCardDetails(UserId, token)
      return data
    } catch (error) {
      console.error('Error fetching trip license details:', error)
      throw new Error('Error fetching trip license details')
    }
  }

  const { data: FqnCardDetails = {} } = useQuery(
    'getTripLicenseFqnDetails',
    fetchTripFQNDetails
  )

  useEffect(() => {
    if (FqnCardDetails && FqnCardDetails.fqnExpirationDate) {
      const currentDate = new Date().toISOString().split('T')[0]
      const fanDate = FqnCardDetails.fqnExpirationDate
      const fqnImage = FqnCardDetails.fqnCard
      if (fanDate > currentDate) {
        setLicenseFQNExpiryDate(fanDate)
        setSelectedFQNImagePath(fqnImage)
        setinitialFQNLicenseDate(fanDate)
        setinitialFQNImagePath(fqnImage)
      } else {
        /*empty*/
      }
    }
  }, [FqnCardDetails])

  const handleSubmit = async () => {
    let isValid = true;
    if (licenseForm) {
      if (!licenseExpiryDate) {
        const errorMsg = t('Please_License_Date');
        setErrorMessage(errorMsg);
        isValid = false;
      } else {
        setErrorMessage('');
      }
      if (!isValid) {
        return;
      }
      try {
        setErrorMessageImage('');
        setErrorMessage('');
        onNext({
          licenseExpiry: licenseExpiryDate,
        });
      } catch (error) {
        console.error('Error uploading image to Firebase:', error);
        setErrorMessage('Error uploading image to Firebase. Please try again.');
      }
    }

    if (driverCard) {
      if (!cardExpiryDate) {
        const errorMsg = t('Driver_Card_Expiry');
        setErrorMessage(errorMsg);
        isValid = false;
      } else {
        setErrorMessage('');
      }
      if (!isValid) {
        return;
      }
      try {
        setErrorMessageImage('');
        setErrorMessage('');
        onNext({
          cardExpiryDate: cardExpiryDate,
        });
      } catch (error) {
        console.error('Error uploading image to Firebase:', error);
        setErrorMessage('Error uploading image to Firebase. Please try again.');
      }
    }

    if (fqnCard) {
      if (!licenseFQNExpiryDate) {
        const errorMsg = t('Fqu_Card_Expiry');
        setErrorMessage(errorMsg);
        isValid = false;
      } else {
        setErrorMessage('');
      }
      if (!isValid) {
        return;
      }
      try {
        setErrorMessageImage('');
        setErrorMessage('');
        onNext({
          fqnExpirationDate: licenseFQNExpiryDate,
        });
      } catch (error) {
        console.error('Error uploading image to Firebase:', error);
        setErrorMessage('Error uploading image to Firebase. Please try again.');
      }
    }
  };
  AuthChecker()
  return (
    <>
      {
        <div className="d-flex align-items-center justify-content-center m-auto height">
          <form
            className="main-container"
            onSubmit={(e) => {
              e.preventDefault()
              handleSubmit()
            }}>
            <div className="userHeading">
              <h6 className="textcolor">
                {licenseForm
                  ? t('License_Details')
                  : driverCard
                    ? t('Upload_card')
                    : fqnCard && t('Upload_FQN')}
              </h6>
            </div>

            <div className="flex-column d-flex p-3 ">

              {licenseForm ? (
                <>
                <input
                  type="date"
                  className="inputform bg-white"
                  style={{ width: '100%' }}
                  value={licenseExpiryDate}
                  onChange={handleLicense}

                min={today}
                />
                <small className='my-1 text-danger'>{t("vaild_license")}</small>
                  </>
              ) : driverCard ? (
                  <>
                <input
                  type="date"
                  className="inputform bg-white"
                  style={{ width: '100%' }}
                  value={cardExpiryDate}
                  onChange={handleDriver}

                min={today}
                  />
                <small className='my-1 text-danger'>{t("vaild_driver")}</small>
                  </>
              ) : (
                    fqnCard && (
                      <>
                  <input
                    type="date"
                    className="inputform bg-white"
                    style={{ width: '100%' }}
                    value={licenseFQNExpiryDate}
                    onChange={handleFqn}
                  min={today}
                      />
                <small className='my-1 text-danger'>{t("valid_card")}</small>
                      </>
                )
              )}
              {!imageUploaded && errorMessage && (
                <div className="error-message">{errorMessage}</div>
              )}
              {errorMessageInput && licenseForm ? <div style={{ color: 'red' }} className="error-message">{errorMessageInput}</div> : null}
              {errorMessageInput1 && driverCard ? <div style={{ color: 'red' }} className="error-message">{errorMessageInput1}</div> : null}
              {errorMessageInput2 && fqnCard ? <div style={{ color: 'red' }} className="error-message">{errorMessageInput2}</div> : null}



            </div >

            <div
              className="d-flex align-items-center justify-content-center  mb-3 mt-5 clock" style={{ gap: 50 }}>
              <div className="d-flex justify-content-center flex-row  align-items-center gap-2" >
                <h6 className="" onClick={() => onPrevious()} style={{ cursor: 'pointer', color: '#05653F', fontSize: '20px' }} >
                  {t("back")}
                </h6>
                <FontAwesomeIcon
                  icon={faChevronCircleLeft}
                  color="#05653F"
                  className=" backIcon"
                  onClick={() => onPrevious()}
                />

              </div>
              <div className="d-flex justify-content-center flex-row  align-items-center gap-2" >
                <FontAwesomeIcon
                  icon={faChevronCircleRight}
                  color="#05653F"
                  className="backIcon"
                  onClick={handleSubmit}
                />
                <h6 className="" onClick={handleSubmit} style={{ cursor: 'pointer', color: '#05653F', fontSize: '20px' }} >
                  {t("further")}
                </h6>
              </div>

            </div>
          </form >
        </div >
      }
    </>
  )
}

export default LicenseForm
