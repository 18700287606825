import React, { useState } from 'react'
import Modal from './Modal'
import { useMutation } from 'react-query'
import { createUser } from '../services/ApiServices'
import Loader from 'react-js-loader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'

interface UserData {
  username: string
  hashedPassword: string
  firstname: string
  lastname: string
  phonenumber: string
  license_number: string
}
const CreateUserModal = ({ onClose }: { onClose: () => void }) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [usernameError, setUsernameError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [firstname, setFirstName] = useState('')
  const [lastname, setLastName] = useState('')
  const [license_number, setLicense_number] = useState('')
  const [phonenumber, setPhoneNo] = useState('')
  const [phonenumberError, setPhonenumberError] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  async function hashPassword(password: string): Promise<string> {
    const encoder = new TextEncoder()
    const data = encoder.encode(password)
    const hashBuffer = await window.crypto.subtle.digest('SHA-256', data)
    const hashArray = Array.from(new Uint8Array(hashBuffer))
    const hashHex = hashArray
    .map((byte) => byte.toString(16).padStart(2, '0'))
    .join('')
    return hashHex
  }

  const { t } = useTranslation()
  const createUserMutation = useMutation<void, Error, UserData>(
    (userData: UserData) =>
      createUser(
        userData.username,
        userData.hashedPassword,
        userData.firstname,
        userData.lastname,
        userData.phonenumber,
        userData.license_number
      )
  )
  const isSuccessful = (createUserMutation.data as any)?.isSuccessful
  const message = (createUserMutation.data as any)?.msg

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault()
    setLoading(true)
    const hashedPassword = await hashPassword(password);
    try {
      await createUserMutation.mutate({
        username,
        hashedPassword,
        firstname,
        lastname,
        phonenumber,
        license_number
      })
    } catch (error) {
      console.error('Error creating user:', error)
    } finally {
      setLoading(false)
      console.error('Error creating user:', error)
    }
  }

  React.useEffect(() => {
    if (isSuccessful === true) {
      onClose()
    }
    if (isSuccessful === false) {
      setError(message)
    }
  }, [
    createUserMutation.isSuccess,
    createUserMutation.isError,
    createUserMutation.error,
    onClose
  ])

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setUsername(value)
    if (!/^[0-9]+$/.test(value)) {
      setUsernameError('Username can only contain numbers.')
    } else {
      setUsernameError('')
    }
  }

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setPassword(value)
    if (value.length < 6) {
      setPasswordError('Password must be at least 6 characters long.')
    } else {
      setPasswordError('')
    }
  }

  const handlePhonenumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setPhoneNo(value)
    if (!/^[0-9]+$/.test(value)) {
      setPhonenumberError('Phone number can only contain numbers.')
    } else {
      setPhonenumberError('')
    }
  }


  const isValidForm = () => {
    return (
      username &&
      password &&
      firstname &&
      lastname &&
      license_number &&
      phonenumber &&
      !usernameError &&
      !passwordError &&
      !phonenumberError
    )
  }
  return (
    <Modal onClose={onClose} contentWidth={'25%'}>
      <div className="modal-header">
        <h4>{t('createUser')}</h4>
        <FontAwesomeIcon icon={faTimes} className="close" onClick={onClose} />
      </div>
      {error && <div className="alert alert-danger">{error}</div>}

      <form onSubmit={handleSubmit}>
        <div className="inputHeight mt-1">
          <label htmlFor="username">{t('userName')}</label>
          <input
            type="text"
            id="username"
            placeholder={t('userName')}
            value={username}
            style={{ width: '100%' }}
            onChange={handleUsernameChange}
            required
          />
          {usernameError && (
            <span className="error-message">{usernameError}</span>
          )}
        </div>

        <div className="inputHeight mt-1">
          <label htmlFor="password">{t('password')}</label>
          <input
            type="password"
            placeholder={t('password')}
            id="password"
            value={password}
            onChange={handlePasswordChange}
            required
            className="inputModal"
          />
          {passwordError && (
            <span className="error-message">{passwordError}</span>
          )}
        </div>

        <div className="inputHeight mt-1">
          <label htmlFor="first_name">{t('FirstName')}</label>
          <input
            type="text"
            id="first_name"
            placeholder={t('FirstName')}
            value={firstname}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
        </div>

        <div className="inputHeight mt-1">
          <label htmlFor="last_name">{t('LastName')}</label>
          <input
            type="text"
            id="last_name"
            placeholder={t('LastName')}
            value={lastname}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
        </div>

        <div className="inputHeight mt-1">
          <label htmlFor="license_number">{t('LicenseNo')}</label>
          <input
            type="text"
            id="license_number"
            placeholder={t('LicenseNo')}
            value={license_number}
            onChange={(e) => setLicense_number(e.target.value)}
            required
          />
        </div>

        <div className="inputHeight mt-1">
          <label htmlFor="phonenumber">{t('PhoneNo')}</label>
          <input
            type="text"
            id="phonenumber"
            placeholder={t('PhoneNo')}
            value={phonenumber}
            onChange={handlePhonenumberChange}
            required
          />
          {phonenumberError && (
            <span className="error-message">{phonenumberError}</span>
          )}
        </div>

        <div className="d-flex align-items-center justify-content-center pt-3">
          {loading ? (
            <Loader
              type="bubble-top"
              bgColor={'#05653F'}
              color={'#05653F'}
              size={50}
            />
          ) : (
            <button
              type="submit"
              disabled={loading || !isValidForm()}
              style={{
                backgroundColor: loading || !isValidForm() ? 'grey' : '#05653F',
                color: 'white',
                cursor: loading || !isValidForm() ? 'not-allowed' : 'pointer'
              }}>
              {t('Submit')}
            </button>
          )}
        </div>
      </form>
    </Modal>
  )
}

export default CreateUserModal
