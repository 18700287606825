import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronCircleLeft,
  faPencil
} from '@fortawesome/free-solid-svg-icons'
import { Dispatch, SetStateAction } from 'react';
import AuthChecker from '../libs/AuthChecker';

interface FormData {
  signatureDataURL: string | null;
}

interface SubmitFormProps {
  onNext: (formData: FormData) => void;
  onPrevious: () => void;
  onClick: () => void;
  onSignatureSaved: (signatureDataURL: string | null) => Promise<void>;
  handleFinalSubmit: () => Promise<void>;
  validationError: boolean;
  setValidationError: Dispatch<SetStateAction<boolean>>;
  swiperIndex: number;
  SelectedUser?:any;
  signatureArea?:any
}

const SubmitForm = ({
  onNext,
  onPrevious,
  onClick,
  SelectedUser,
  signatureArea

}: SubmitFormProps) => {
  const [signatureDataURL] = useState(null)
  const { t } = useTranslation()


  const handleSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault()
    if (signatureDataURL) {
      const formData = {
        signatureDataURL: signatureDataURL
      }
      onNext(formData)
    }
  }
  AuthChecker()
  return (
    <div className="d-flex align-items-center justify-content-center m-auto height">
      <form onSubmit={handleSubmit} className="main-container createTourSignatureModal">
        <div className=" userHeading">
          <h2 className="textcolor">{SelectedUser!=="Driver"?<> <span>{t("Signature_Text")}</span>
        <span style={{fontWeight:"900",textDecoration:"underline"}}>{ t("Signature_Text1")}</span><span>{t("Signature_Text11")}</span></> :t('signature_text')}</h2>
        </div>
        <div className="flex-column d-flex p-3">
        {SelectedUser==="Driver"&&<><p className="signaText">{t('signature_text1')}<span style={{fontWeight:"900",textDecoration:"underline"}}>{t('signature_text11')}</span>{t('signature_text111')}</p>
          <p className="signaText">{t('signature_text2')}</p>
          <p className="signaText">{t('signature_text3')}</p>
          <p className="signaText">{t('signature_text4')}</p>
          <p className="signaText">{t('signature_text5')}</p>
          <p className="signaText">{t('signature_text6')}</p></>}

         {SelectedUser==="Driver"? <div className="submitIcon mt-2" onClick={onClick}>
            <FontAwesomeIcon
              icon={faPencil}
              height={25}
              width={25}
              color={'blue'}
              onClick={onClick}
            />
          </div>: signatureArea}

          <div className="d-flex align-items-center justify-content-center  mb-1 mt-3 clock">
            <FontAwesomeIcon
              icon={faChevronCircleLeft}
              color="#05653F"
              className=" backIcon"
              onClick={() => onPrevious()}
            />
          </div>
        </div>
      </form>
    </div>
  )
}

export default SubmitForm
