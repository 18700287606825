import { faChevronCircleLeft, faChevronCircleRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiDeleteBin5Fill } from "react-icons/ri";
import Modal from 'react-modal';
import Button from './Button';

interface PauseTime {
  start_time: string;
  end_time: string;
}

const StepPauseTimes: any = ({
  breaks,
  handlePauseTimeChange,
  handleEndTimeChange,
  handleRemoveBreak,
  addBreak,
  onNext,
  onPrevious,
  errorDumped,
  handleDumpChange,
  dumped,
  isDumped,
  totalSelected,
  endTimeErrors,
  startTimeErrors
}: {
  breaks: PauseTime[];
  handlePauseTimeChange: (index: number, value: string, field: 'start_time' | 'end_time') => void;
  handleEndTimeChange: (index: number, value: string, field: 'start_time' | 'end_time') => void;
  handleRemoveBreak: (index: number) => void;
  addBreak: () => void;
  onNext: () => void;
  onPrevious: () => void;
  errorDumped?: string;
  handleDumpChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  dumped?: string;
  isDumped: boolean;
  totalSelected?: any;
  endTimeErrors?: any;
  startTimeErrors?: any;
}) => {
  const { t } = useTranslation();
  const [delectModal, setDelectModal] = useState<boolean>(false);
  const [indexToDelete, setIndexToDelete] = useState<number | null>(null);

 const [selectedDriver, setSelectedDriver] = useState<string>('');
console.log('Machine Operator',selectedDriver)
  useEffect(() => {
    const storedDriver = localStorage.getItem('selectedDriver');
    if (storedDriver) {
      setSelectedDriver(storedDriver);
    }
  }, []);


  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (onNext) {
        onNext();
      }
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const selectDriver = localStorage.getItem('selectedDriver');

  const handleYes = () => {
    if (indexToDelete !== null) {
      handleRemoveBreak(indexToDelete);
    }
    setDelectModal(false);
  };

  return (
    <div className="d-flex align-items-center justify-content-center m-auto height">
      <form className="main-container" onSubmit={handleSubmit}>
        <div className="userHeading" style={{ background: "#F6821F" }}>
          <h2 className="textcolor">{isDumped ? t("dumped_hed") : `${t("pause1")}`}</h2>
        </div>
        <div className="d-flex flex-column p-3">
          {isDumped ? (
            <div className="d-flex flex-row mt-1 mb-2 flex-column">
              <input
                type="text"
                placeholder={t('Dump_count')}
                className="inputform mt-3"
                value={dumped}
                onChange={handleDumpChange}
                onKeyDown={handleKeyPress}
              />
              <span
                className="error-message"
                style={{
                  minHeight: '22px',
                  display: 'block',
                  color: 'red'
                }}
              >
                {errorDumped}
              </span>
              <p className="mt-1 font-weight-bold mb-3">
                {t("Dump_Note")}<span style={{ fontWeight: '900' }}>0.</span>
              </p>
            </div>
          ) : (
            <>
              <h4 className=" text-left mb-3" style={{ fontSize: '16px' }}>
                {t('Breaks')}:
              </h4>
              <div className="d-flex align-items-center justify-content-center pauseInput w-100 mw-100 clock mb-3 flex-column">
                <div className='w-100' style={{ maxHeight: '160px', overflowY: breaks.length > 3 ? "scroll" : "auto", marginBottom: "4px" }}>
                  {breaks.map((pauseTime, index) => (
                    <div className="d-flex flex-row clock" style={{ width: '100%' }} key={index}>
                      <div className='d-flex flex-column w-100'>
                        <div className="w-100" style={{ height: '75px' }}>
                          <h6 className="col-12 inputHeading">{t('PauseFrom')}</h6>
                          <input
                            type="time"
                            className="inputform w-100"
                            style={{ background: 'white' }}
                            value={pauseTime?.start_time || ''}
                            onChange={(e) => handlePauseTimeChange(index, e.target.value, 'start_time')}
                          />
                        </div>
                        {startTimeErrors[index] && <span className="error-message">{startTimeErrors[index]}</span>}
                      </div>
                      <div className='d-flex flex-column w-100'>
                        <div className="w-100" style={{ height: '75px' }}>
                          <h6 className=" inputHeading">{t('PauseTo')}</h6>
                          <input
                            type="time"
                            className="inputform w-100"
                            style={{ background: 'white' }}
                            value={pauseTime?.end_time || ''}
                            onChange={(e) => handleEndTimeChange(index, e.target.value, 'end_time')}
                          />
                        </div>
                        {endTimeErrors[index] && <span className="error-message">{endTimeErrors[index]}</span>}
                      </div>
                      <div className="d-flex align-items-center">
                        <RiDeleteBin5Fill className="delectIconP"
                          onClick={() => {
                            setDelectModal(true);
                            setIndexToDelete(index);
                          }}
                          color='red' />
                      </div>
                    </div>
                  ))}
                </div>
                <Button onButtonClick={addBreak} title={`${t("new_break")}`} backgroundColor="#F6821F" color="white" />
              </div>
              {selectDriver === 'Driver' ? (
                <p className='mt-1 font-weight-bold mb-2'>
                  {t("Paused_Time")}
                  <span style={{ fontWeight: '900', textDecoration: 'underline' }}>
                    {t("Paused_Time1")}
                  </span>
                  {t("Paused_Time11")}
                </p>
              ) : selectDriver === 'Loader' ? (
                <p className='mt-1 font-weight-bold mb-2'>
                  {t("Paused_Time")}
                  <span style={{ fontWeight: '900', textDecoration: 'underline' }}>
                    {t("Paused_Time1")}
                  </span>
                  {t("Paused_Time1L")}
                </p>
              ) : null}
            </>
          )}

          <div className="d-flex align-items-center justify-content-center mb-2 clock" style={{ gap: 50 }}>
            {selectedDriver === 'Loader' || selectDriver === 'Machine Operator' ? null :(
              <div className="d-flex justify-content-center flex-row align-items-center gap-1">
                <h6 className="" onClick={onPrevious} style={{ cursor: 'pointer', color: '#F6821F', fontSize: '20px' }}>
                  {t("back")}
                </h6>
                <FontAwesomeIcon
                  icon={faChevronCircleLeft}
                  color="#F6821F"
                  className=" backIcon"
                  onClick={onPrevious}
                />
              </div>
            )}
            <div className="d-flex justify-content-center flex-row align-items-center gap-1">
              <FontAwesomeIcon
                icon={faChevronCircleRight}
                color="#F6821F"
                className="backIcon"
                onClick={onNext}
              />
              <h6 className="" onClick={onNext} style={{ cursor: 'pointer', color: '#F6821F', fontSize: '20px' }}>
                {t("further")}
              </h6>
            </div>
          </div>
        </div>
      </form>

      <Modal
        isOpen={delectModal}
        onRequestClose={() => setDelectModal(false)}
        style={{
          content: {
            height: 290,
            width: 285,
            margin: 'auto',
            padding: 10
          }
        }}
      >
        <div className="d-flex align-items-center justify-content-end">
          <FontAwesomeIcon
            icon={faTimes}
            className="crossIcon"
            onClick={() => setDelectModal(false)}
            color="#05653F"
          />
        </div>
        <h5 className='mt-3 px-2'>{t("delect_text_h")}</h5>
        <div className="d-flex align-items-center justify-content-center flex-row gap-3 mt-5">

          <Button
            backgroundColor={'green'}
            color='white'
            border={'2px solid green'}
            onButtonClick={handleYes}
            title={t('delect')}
            width="110px"
          />
          <Button
            title={t('close')}
            backgroundColor={'red'}
            color='white'
            border={'2px solid red'}
            onButtonClick={() => setDelectModal(false)}
            width="110px"
            style={{ paddingLeft: '0px', paddingRight: '0px' }}
          />
        </div>

      </Modal>
    </div>
  );
};

export default StepPauseTimes;
