import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEdit,
  faTrashAlt
} from '@fortawesome/free-solid-svg-icons'
import { useQuery, useMutation, QueryClient } from 'react-query'
import CreateUserModal from '../components/CreateUserModal'
import Spinner from '../components/Spinner'
import Button from '../components/Button'
import UserUpdate from '../components/UserUpdate'
import DeleteAlert from '../components/DeleteAlert'
import { apiDeleteUser, apiGetAllUserDetails, updateidentification } from '../services/ApiServices'
import { useTranslation } from 'react-i18next'
import AuthChecker from '../libs/AuthChecker'
import { AuthDetails } from '../libs/authDetails'
import Header from '../components/Header'
import Icon from '../assets/imagesPng/avtar.png'

interface UserData {
  emailId: string | null;
  firstname: string;
  lastname: string;
  license_number: string;
  phonenumber: string;
  profile_picture: string;
  user_id: number;
  username: string;
  is_driver: number;
}

const Admin = () => {
  const [isCreateUserModalOpen, setIsCreateUserModalOpen] = useState(false)
  const { token } = AuthDetails()
  const [isUpdate, setIsUpdate] = useState({ isOpen: false, userData: null })
  const [isDelete, setIsDelete] = useState(false)
  const [userIdToDelete, setUserIdToDelete] = useState<number>(0)
  const { t } = useTranslation()
 const [selection, setSelection] = useState<{ [key: number]: boolean }>(() => {
  try {
    const storedSelection = localStorage.getItem('driverAdmin');
    return storedSelection ? JSON.parse(storedSelection) : {};
  } catch (error) {
    console.error('Error parsing localStorage:', error);
    return {};
  }
});

useEffect(() => {
  localStorage.setItem('driverAdmin', JSON.stringify(selection));
}, [selection]);
  const queryClient = new QueryClient()
  const {
    data: users,
    isLoading,
    refetch
  } = useQuery('users', () => apiGetAllUserDetails(token))
  const userList = Array.isArray(users) ? users : []

  const handleUpdateUser = (userData: any) => {
    setIsUpdate({ isOpen: true, userData })
  }

  AuthChecker()

  const deleteUserMutation = useMutation(
    ({ userId, token }: { userId: number; token: string }) =>
      apiDeleteUser(userId, token),
    {
      onSuccess: () => {
        refetch()
        queryClient.invalidateQueries('users')
        setIsDelete(false)
      },
      onError: (error) => {
        console.error('Error deleting user:', error)
      }
    }
  )

  const handleDeleteUser = (userId: number) => {
    setUserIdToDelete(userId)
    setIsDelete(true)
  }

  const handleConfirmDelete = () => {
    deleteUserMutation.mutate({ userId: userIdToDelete, token })
  }

 const handleToggleDriverStatus = async (userId: number, isDriver: number) => {
  try {
    const newStatus = selection[userId] ? 0 : 1;

    await updateidentification(newStatus, userId, token);

    setSelection((prevState) => {
      const updatedSelection = {
        ...prevState,
        [userId]: !prevState[userId],
      };

      localStorage.setItem('driverAdmin', JSON.stringify(updatedSelection));
      return updatedSelection;
    });

  } catch (error) {
    console.error('Error updating driver status:', error);
  }
};

  return (
    <>
      <Header style={{ paddingTop: "16px", paddingBottom: "16px" }} />
      <div className="mb-3 overFlow p-3 admin">
        <div className="col-md-12 scroll">
          <div className="d-flex align-items-center justify-content-end flex-row mb-2">
            <Button
              title={t('createUser')}
              backgroundColor={'#05653F'}
              color={'white'}
              onButtonClick={() => setIsCreateUserModalOpen(true)}
            />
          </div>
          <table className="tables">
            <thead className="tableHeading">
              <tr>
                <th className=" action items">{t('Sno')}</th>
                <th className=" action items">{t('userName')}</th>
                <th className="col-2 items">{t('Image')}</th>
                <th className="col-2 providerName items">{t('FirstName')}</th>
                <th className="col-2 items">{t('LastName')}</th>
                <th className="col-2 items">{t('LicenseNo')}</th>
                <th className="col-2 action items">{t('Action')}</th>
              </tr>
            </thead>

            <tbody className="tableBody">
              {isLoading ? (
                <tr>
                  <td colSpan={8} className="text-white">
                    <Spinner />
                  </td>
                </tr>
              ) : userList?.length === 0 ? (
                <tr>
                  <td colSpan={8} className="text-white">
                    {t('noData')}
                  </td>
                </tr>
              ) : (
                userList.map((user: UserData, index: number) => (
                  <tr key={index}>
                    <td className=" action">{index + 1}</td>
                    <td className="action">{user.username}</td>
                    <td className="col-2 items">
                      <img src={user.profile_picture ? user.profile_picture : Icon} alt="User Icon" className="profilePicture" />
                    </td>
                    <td className="col-2 items">{user.firstname}</td>
                    <td className="col-2 items">{user.lastname}</td>
                    <td className="col-2 items">{user.license_number}</td>
                    <td className="col-2 action items">
                      <FontAwesomeIcon
                        icon={faEdit}
                        className="sidebar-icon"
                        onClick={() => handleUpdateUser(user)}
                      />
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        className="sidebar-icon"
                        onClick={() => handleDeleteUser(user.user_id)}
                      />
                      <input
                        type="checkbox"
                        checked={selection[user.user_id] ?? user.is_driver === 1}
                        style={{ height: '20px', width: '20px', marginBottom: '5px' }}
                        onChange={() => handleToggleDriverStatus(user.user_id, user.is_driver)}
                      />

                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>

        {isCreateUserModalOpen && (
          <CreateUserModal
            onClose={() => {
              setIsCreateUserModalOpen(false)
              refetch()
            }}
          />
        )}
        {isUpdate.isOpen && (
          <UserUpdate
            onClose={() => {
              setIsUpdate({ isOpen: false, userData: null })
              refetch()
            }}
            userData={isUpdate?.userData || undefined}
          />
        )}
        {isDelete && (
          <DeleteAlert
            message={t('deleteUser')}
            onClose={() => setIsDelete(false)}
            onDelete={handleConfirmDelete}
            imageUri={() => { }}
            breaks={[]}
            titile={t('Yes')}
            titileN={t('No')}
          />
        )}
      </div>
    </>
  )
}

export default Admin
