import React from 'react'

const Spinner = ({ color }: { color?: string }) => {
  const spinnerStyle = {
    backgroundColor: color || '#05653F'
  }
  return (
    <div className="spinner">
      <div className="bounce1" style={spinnerStyle}></div>
      <div className="bounce2" style={spinnerStyle}></div>
      <div className="bounce3" style={spinnerStyle}></div>
    </div>
  )
}

export default Spinner
