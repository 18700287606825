import { USER_DATA } from '../constant/constants'
import { getDataFromLocal } from '../storage/storage'

export const AuthDetails = () => {
  const user = getDataFromLocal(USER_DATA)
  const userId = user?.user?.user_id
  const token = user?.token
  const email = user?.user?.email
  const username = user?.user?.username
  const is_admin = user?.user?.is_admin
  const password = user?.user?.password
  const language = user?.user?.language
  return { user, userId, token, email, username, is_admin, password, language }
}

// import { USER_DATA } from '../constant/constants'
// import { getDataFromLocal } from '../storage/storage'

// export const AuthDetails = () => {
//   const user = getDataFromLocal(USER_DATA) || {};
//   return {
//     user: user.user || {},
//     userId: user?.user?.user_id || null,
//     token: user?.token || null,
//     email: user?.user?.email || '',
//     username: user?.user?.username || '',
//     is_admin: user?.user?.is_admin || false,
//     password: user?.user?.password || '',
//     language: user?.user?.language || 'en',
//   };
// };