import {
  faChevronCircleRight
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const WelcomeStep = (
  { onNext,
    selectedUserType }:
    {
      onNext: () => void
      selectedUserType?: any
    }) => {
  const { t } = useTranslation()
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const handleSubmit = (e: { preventDefault: () => void }) => {
    if (e) {
      e.preventDefault()
    }
    onNext()
  }
  const handleBackClick = () => {
    localStorage.removeItem('startandPauseData')
    localStorage.removeItem('errorMessageInput');
    localStorage.removeItem('errorMessageInput1');
    localStorage.removeItem('errorMessageInput2');
    localStorage.removeItem('selectedUser')
    localStorage.removeItem('co_driver')
    localStorage.removeItem('isdriver')
    localStorage.removeItem("signatureImage")
    localStorage.removeItem('isCheckboxChecked')
    localStorage.removeItem("isSelectedDriver")
    localStorage.removeItem('OptionalPassenger')
    localStorage.removeItem('driverDetailFormData')
    localStorage.removeItem('tripAndVehicleTypeData')
    localStorage.removeItem('licenseFormData')
    localStorage.removeItem('garbageFormData')
    localStorage.removeItem('signatureDataURL')
    localStorage.removeItem('currentStep')
    localStorage.removeItem('mileageData')
    localStorage.removeItem('pauseTimes')
  }
  useEffect(() => {
    handleBackClick()
  }, [])

  useEffect(() => {
    const storedData = localStorage.getItem('user')
    if (storedData) {
      const parsedData = JSON.parse(storedData)
      if (parsedData.user) {
        const user = parsedData.user
        const capitalize = (str: string) =>
          str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
        const firstName = capitalize(user.firstname || '')
        const lastName = capitalize(user.lastname || '')
        setFirstName(firstName)
        setLastName(lastName)
      }
    }
  }, [])

  return (
    <>
      <div className="welcome-container-main">
        <div className="d-flex align-items-center justify-content-center mt-5 flex-column " style={{ width: '82%' }}>
          {/* <h1 className="welcomeBack text-center">{`
         ${t('Hello')}, ${firstName} ${lastName}`}! <br /> {selectedUserType === "Driver" ? t('Welcome_Back') : t('Welcome_Back1')}</h1> */}
          <h1 className="welcomeBack text-center">
            {`${t('Hello')}, ${firstName} ${lastName}`}! <br />
            {selectedUserType === "Driver" ? (
              <span dangerouslySetInnerHTML={{ __html: t('Welcome_Back') }} />
            ) : (
              <span dangerouslySetInnerHTML={{ __html: t('Welcome_Back1') }} />
            )}
          </h1>

        </div>
        <div className="d-flex justify-content-center flex-row mt-5 mb-2 align-items-center gap-1">
          <FontAwesomeIcon
            icon={faChevronCircleRight}
            color="#05653F"
            className="welcomeIcon"
            onClick={handleSubmit}
          />
          <h6 className="" onClick={handleSubmit} style={{ cursor: 'pointer', color: '#05653F', fontSize: '20px' , fontWeight:'bold'}} >
            {t("start_day")}
          </h6>
        </div>

      </div>
    </>
  )
}

export default WelcomeStep
