// import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import React, { useState } from 'react';
// import { useTranslation } from 'react-i18next';

// const Calendar = ({ onDateChange }: any) => {
//   const today = new Date();
//   const [currentDate, setCurrentDate] = useState(() => {
//     const savedDate = localStorage.getItem('selectedDate');
//     return savedDate ? new Date(savedDate) : today;
//   });

//   const [selectedDate, setSelectedDate] = useState<Date | null>(() => {
//     const savedDate = localStorage.getItem('selectedDate');
//     return savedDate ? new Date(savedDate) : today;
//   });

//   const { t } = useTranslation();

//   const renderWeek = (startDay: Date) => {
//     const days = [];

//     for (let i = 0; i < 7; i++) {
//       const day = new Date(startDay);
//       day.setDate(startDay.getDate() + i);
//       const isCurrentDate = day.toDateString() === today.toDateString();
//       const isSelected = selectedDate && selectedDate.toDateString() === day.toDateString();
//       const dayStyle = {
//         backgroundColor: isSelected
//           ? 'rgb(250, 249, 246)'
//           : isCurrentDate
//             ? '#E6E3D3'
//             : 'inherit',
//         color: isSelected ? 'black' : isCurrentDate ? 'black' : ''
//       };

//       days.push(
//         <div
//           className={`calendar-day ${day.getMonth() !== currentDate.getMonth() ? 'disabled' : ''} ${isSelected ? 'selected' : ''}`}
//           key={i}
//           onClick={() => handleDateClick(day)}
//           style={dayStyle}
//         >
//           {day.getDate()}
//         </div>
//       );
//     }
//     return days;
//   };

//   const prevWeek = () => {
//     const newDate = new Date(currentDate);
//     newDate.setDate(currentDate.getDate() - 7);
//     setCurrentDate(newDate);

//     const newStartOfWeek = new Date(newDate);
//     newStartOfWeek.setDate(newDate.getDate() - newDate.getDay());

//     setSelectedDate(newStartOfWeek);
//     onDateChange(newStartOfWeek);
//   };

//   const nextWeek = () => {
//     const newDate = new Date(currentDate);
//     newDate.setDate(currentDate.getDate() + 7);
//     setCurrentDate(newDate);

//     const newStartOfWeek = new Date(newDate);
//     newStartOfWeek.setDate(newDate.getDate() - newDate.getDay());

//     setSelectedDate(newStartOfWeek);
//     onDateChange(newStartOfWeek);
//   };

//   const handleDateClick = (date: any) => {
//     setSelectedDate(date);
//     onDateChange(date);
//   };

//   const weekNames = [
//     t('week.sunday'),
//     t('week.monday'),
//     t('week.tuesday'),
//     t('week.wednesday'),
//     t('week.thursday'),
//     t('week.friday'),
//     t('week.saturday'),
   
//   ];

//   return (
//     <>
//       <div className="calendar-container">
//         <div className="calendar-header">
//           <FontAwesomeIcon
//             icon={faArrowLeft}
//             onClick={prevWeek}
//             color="white"
//             className="arrowIcon"
//           />

//           <h2>
//             {t('months.' + currentDate.getMonth())} {currentDate.getFullYear()}
//           </h2>

//           <FontAwesomeIcon
//             icon={faArrowRight}
//             onClick={nextWeek}
//             color="white"
//             className="arrowIcon"
//           />
//         </div>
//         <div className="calendar-days">
//           <div className="calendar-day-names">
//             {weekNames.map((day, index) => (
//               <div className="calendar-day-name" key={index}>
//                 {day}
//               </div>
//             ))}
//           </div>
//           <div className="calendar-week">
//             {renderWeek(
//               new Date(
//                 currentDate.getFullYear(),
//                 currentDate.getMonth(),
//                 currentDate.getDate() - currentDate.getDay()
//               )
//             )}
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Calendar;

import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const Calendar = ({ onDateChange }: any) => {
  const today = new Date();
  const [currentDate, setCurrentDate] = useState(() => {
    const savedDate = localStorage.getItem('selectedDate');
    return savedDate ? new Date(savedDate) : today;
  });

  const [selectedDate, setSelectedDate] = useState<Date | null>(() => {
    const savedDate = localStorage.getItem('selectedDate');
    return savedDate ? new Date(savedDate) : today;
  });

  const { t } = useTranslation();

  const renderWeek = (startDay: Date) => {
    const days = [];

    for (let i = 0; i < 7; i++) {
      const day = new Date(startDay);
      day.setDate(startDay.getDate() + i);
      const isCurrentDate = day.toDateString() === today.toDateString();
      const isSelected = selectedDate && selectedDate.toDateString() === day.toDateString();
      const dayStyle = {
        backgroundColor: isSelected
          ? 'rgb(250, 249, 246)'
          : isCurrentDate
            ? '#E6E3D3'
            : 'inherit',
        color: isSelected ? 'black' : isCurrentDate ? 'black' : ''
      };

      days.push(
        <div
          className={`calendar-day ${day.getMonth() !== currentDate.getMonth() ? 'disabled' : ''} ${isSelected ? 'selected' : ''}`}
          key={i}
          onClick={() => handleDateClick(day)}
          style={dayStyle}
        >
          {day.getDate()}
        </div>
      );
    }
    return days;
  };

  const prevWeek = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(currentDate.getDate() - 7);
    setCurrentDate(newDate);

    const newStartOfWeek = new Date(newDate);
    newStartOfWeek.setDate(newDate.getDate() - newDate.getDay());

    setSelectedDate(newStartOfWeek);
    onDateChange(newStartOfWeek);
  };

  const nextWeek = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(currentDate.getDate() + 7);
    setCurrentDate(newDate);

    const newStartOfWeek = new Date(newDate);
    newStartOfWeek.setDate(newDate.getDate() - newDate.getDay());

    setSelectedDate(newStartOfWeek);
    onDateChange(newStartOfWeek);
  };

  const handleDateClick = (date: any) => {
    setSelectedDate(date);
    onDateChange(date);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = new Date(event.target.value);
    setSelectedDate(newDate);
    onDateChange(newDate);
  };

  const weekNames = [
    t('week.sunday'),
    t('week.monday'),
    t('week.tuesday'),
    t('week.wednesday'),
    t('week.thursday'),
    t('week.friday'),
    t('week.saturday'),
  ];

  return (
    <>
      <div className="calendar-container">
        {/* <div className="calendar-header">
          <FontAwesomeIcon
            icon={faArrowLeft}
            onClick={prevWeek}
            color="white"
            className="arrowIcon"
          />

          <h2>
            {t('months.' + currentDate.getMonth())} {currentDate.getFullYear()}
          </h2>

          <FontAwesomeIcon
            icon={faArrowRight}
            onClick={nextWeek}
            color="white"
            className="arrowIcon"
          />
        </div> */}
        {/* <div className="calendar-days">
          <div className="calendar-day-names">
            {weekNames.map((day, index) => (
              <div className="calendar-day-name" key={index}>
                {day}
              </div>
            ))}
          </div>
          <div className="calendar-week">
            {renderWeek(
              new Date(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                currentDate.getDate() - currentDate.getDay()
              )
            )}
          </div>
        </div> */}

        <div className="calendar-input-container">
          <input
            type="date"
            value={selectedDate ? selectedDate.toISOString().split('T')[0] : ''}
            onChange={handleInputChange}
            className="inputform w-50"
          />
        </div>
      </div>
    </>
  );
};

export default Calendar;
