export const LOGIN = "api/users/loginByEmailID";
export const USER_TRIP = "api/userDetails/getUserTripDetails"
export const CREATE_USER = "api/users/createUser"
export const CREATE_DEATAILS = "api/userDetails/createDriverDetails"
export const GET_ALL_USER_DETAILS = 'api/userDetails/getUserDetails'
export const UPDATE_USER_DETAILS = 'api/userDetails/updateUserDetails'
export const DELETE_USER_DETAILS = 'api/userDetails/deleteUserDetailsById'
export const UPDATE_USER_TRIP_DETAILS = 'api/userDetails/updateUserTripDetails'
export const GET_TRIP_DETAILS = 'api/tripTracking/getTripDetails'
export const CREATE_TRIP_TRACKING = 'api/tripTracking/createTripTracking1'
export const GET_VEHICLE_TYPE = 'api/costCenter/getVehicleType'
export const GET_GARBAGE_TYPE = 'api/costCenter/getGarbageType1'
export const DELETE_USER_TRIP = 'api/tripTracking/deleteTripTrackingById'
export const TRIP_EDIT = 'api/tripTracking/updateTripTracking'
export const CREATE_BREAK_POINTS = 'api/breakTrip/createBreakTrip'
export const END_POINTS = 'api/breakTrip/endTrip'
export const GET_ALL_USER_TRIP_DETAILS = 'api/tripTracking/getAllUsersTripDetails'
export const GET_TRIP_LICENSE_DETAILS = 'api/tripTracking/getTripLicenseDetails'
export const GET_USER_PROFILE = 'api/userDetails/getUserProfile'
export const UPDATE_TRIP_STATUS = 'api/breakTrip/updateTripStatus'
export const DRIVER_CARD_DETAILS = 'api/tripTracking/getTripCardDetails'
export const FQN_CARD_DETAILS = 'api/tripTracking/getTripFQNDetails'
export const GET_VEHICLE_NUMBER = 'api/breakTrip/getCarNumberDetails'
export const GET_USER_CAR_DETAILS = 'api/breakTrip/getUserCarDetails'
export const UPDATE_LANGUAGE = 'api/costCenter/updateUserLanguage'
export const LOGIN_EMPLOYEE_LIST = 'api/breakTrip/getUserLoginDetails'
export const Location_List = 'api/costCenter/getLocationList'
export const RESUME_TRIP = 'api/breakTrip/resumeTrip'
export const GET_PAUSE_TRIP = 'api/breakTrip/getPauseTrip'
export const IDENTIFICATION = 'api/users/updateuseridentification'
export const GET_ALL_USER_DETAILS_DRIVER = 'api/userDetails/getUserDetails1'
