import { faChevronCircleLeft, faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface StepSignatureProps {
  submit:any;
  loading: boolean;

}

const StepSignature:any  = ({
  onClick,
  onPrevious
}: {
    onClick?: () => void
  onPrevious?:() => void

}) => {
  const { t } = useTranslation();
  const [selectedDriver, setSelectedDriver] = useState<string>('');
  console.log('Machine Operator',selectedDriver)
    useEffect(() => {
      const storedDriver = localStorage.getItem('selectedDriver');
      if (storedDriver) {
        setSelectedDriver(storedDriver);
      }
    }, []);

  return (
    <div className="d-flex align-items-center justify-content-center m-auto height">
      <form className="main-container ">
        <div className="userHeading" style={{background: "#F6821F"}}>
          <h2 className="textcolor">{selectedDriver === 'Yard Worker' || selectedDriver === 'Workshop Worker' || selectedDriver === 'Locksmith'|| selectedDriver === 'Machine Operator'? t('end_workday'):t("End_Tour")}</h2>
        </div>
        <div className='p-3'>
          <h6 className="mb-3">{t('Signature_requested_here')}</h6>
          {/* <div className='d-flex align-items-center justify-content-center'>
            <div className="submitIcon mt-2" onClick={onClick} style={{background: "#F6821F"}}>
            <FontAwesomeIcon
              icon={faPencil}
              height={25}
              width={25}
              color={'white'}
              onClick={onClick}
            />
          </div>
          </div> */}
        </div>
         <div
              className="d-flex align-items-center justify-content-center  mb-3 mt-4" style={{ gap: 50 }}>
           {selectedDriver === 'Yard Worker' || selectedDriver === 'Workshop Worker' || selectedDriver === 'Locksmith' ? null :   <div className="d-flex justify-content-center flex-row  align-items-center gap-1" >
                <FontAwesomeIcon
                  icon={faChevronCircleLeft}
                  color="#F6821F"
                  className=" backIcon"
                  onClick={onPrevious}
                />
              </div>}

              <div className="submitIcon " onClick={onClick} style={{background: "#F6821F"}}>
            <FontAwesomeIcon
              icon={faPencil}
              height={25}
              width={25}
              color={'white'}
              onClick={onClick}
            />
          </div>
            </div>
      </form>
    </div>
  );
};

export default StepSignature;
