import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from './Button';
import SelectImage from './SelectImage';
import Modal from './Modal';
import Webcam from 'react-webcam';
import { faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface StepDamageQuestionProps {
  setDamagedPicture: (paths: string[]) => void;
  imageUri: (paths: string[]) => void;
  onNextYes: () => void;
  onNextNo: () => void;
  onPrevious: () => void;
  changeSig: () => any
}

const StepDamageQuestion: React.FC<StepDamageQuestionProps> = ({
  setDamagedPicture,
  imageUri,
  onNextYes,
  onNextNo,
  onPrevious,
  changeSig
}) => {
  const { t } = useTranslation();
  const webcamRef = useRef<Webcam>(null);
  const [selectedImages, setSelectedImages] = useState<string[]>([]);
  const [damaged, setDamagedState] = useState(false);
  const [noDamaged, setNoDamagedState] = useState(false);
  const [showSelectImage, setShowSelectImage] = useState(false);
  const [showWebcam, setShowWebcam] = useState<boolean>(false);
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  useEffect(() => {
    const savedImages = localStorage.getItem('damagedImages');
    if (savedImages) {
      const imagesArray = JSON.parse(savedImages);
      setSelectedImages(imagesArray);
      setDamagedPicture(imagesArray);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('damagedImages', JSON.stringify(selectedImages));
  }, [selectedImages]);

  const handleYesClick = () => {
    setDamagedState(true);
    setNoDamagedState(false);
    setShowSelectImage(true);
    changeSig()
  };

  const handleNoClick = () => {
    setNoDamagedState(true);
    onNextNo();
  };

//  const handleCapture = (event: React.MouseEvent<HTMLButtonElement>) => {
//   event.preventDefault();

//   if (selectedImages.length >= 5) {
//     alert(t('Maximum of 5 images allowed.'));
//     return;
//   }

//   if (isMobile) {
//     const input = document.createElement('input');
//     input.type = 'file';
//     input.accept = 'image/*';
//     input.capture = 'environment';
//     input.multiple = false;

//     input.onchange = (event) => {
//       const files = (event.target as HTMLInputElement).files;
//       if (files) {

//         if (files.length > 1) {
//           alert(t('Only one image can be selected on mobile.'));
//           return;
//         }

//         const file = files[0];

//         const reader = new FileReader();
//         reader.readAsDataURL(file);
//         reader.onloadend = () => {
//           if (reader.result) {
//             const newImages = [...selectedImages, reader.result as string];
//             if (newImages.length <= 5) {
//               setSelectedImages(newImages);
//               setDamagedPicture(newImages);
//               imageUri(newImages);
//             } else {
//               alert(t('Maximum of 5 images allowed.'));
//             }
//           }
//         };
//       } else {
//         console.error('No file selected or file input is null');
//       }
//     };
//     input.click();
//   } else if (webcamRef.current) {
//     const imageSrc = webcamRef.current.getScreenshot();
//     if (imageSrc) {
//       const newImages = [...selectedImages, imageSrc];
//       if (newImages.length <= 5) {
//         setSelectedImages(newImages);
//         setDamagedPicture(newImages);
//         imageUri(newImages);
//       } else {
//         alert(t('Maximum of 5 images allowed.'));
//       }
//     } else {
//       console.error('Failed to capture image: screenshot is null');
//     }
//   }
// };

const handleCapture = (event: React.MouseEvent<HTMLButtonElement>) => {
  event.preventDefault();

  if (selectedImages.length >= 5) {
    alert(t('Maximum of 5 images allowed.'));
    return;
  }

  if (isMobile) {
    const hasMobileCapturedImage = selectedImages.some(img =>
      img.startsWith('data:image') && img.length > 0
    );

    if (hasMobileCapturedImage) {
      alert(t('phone_camera'));
      return;
    }

    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.capture = 'environment';
    input.multiple = false;

    input.onchange = (event) => {
      const files = (event.target as HTMLInputElement).files;
      if (files && files.length > 0) {
        const file = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          if (reader.result) {
            const newImages = [...selectedImages, reader.result as string];
            if (newImages.length <= 5) {
              setSelectedImages(newImages);
              setDamagedPicture(newImages);
              imageUri(newImages);
            } else {
              alert(t('Maximum of 5 images allowed.'));
            }
          }
        };
      }
    };
    input.click();
  } else if (webcamRef.current) {
    const imageSrc = webcamRef.current.getScreenshot();
    if (imageSrc) {
      const newImages = [...selectedImages, imageSrc];
      if (newImages.length <= 5) {
        setSelectedImages(newImages);
        setDamagedPicture(newImages);
        imageUri(newImages);
      } else {
        alert(t('Maximum of 5 images allowed.'));
      }
    }
  }
};

  const handleImageSelection = (imageUris: string[]) => {
    if (selectedImages.length + imageUris.length > 5) {
      alert(t('Maximum of 5 images allowed.'));
      return;
    }
    const newImages = [...selectedImages, ...imageUris];
    setSelectedImages(newImages);
    setDamagedPicture(newImages);
    setShowSelectImage(false);
  };


  return (
    <div className="d-flex align-items-center justify-content-center m-auto height">
      <form className="main-container">
        <div className="userHeading" style={{ background: '#F6821F' }}>
          <h2 className="textcolor">{t('Damaged')}</h2>
        </div>
        <div className="p-3">
          {!damaged && !noDamaged ? (
            <>
              <div className="d-flex flex-column align-items-center" style={{ gap: '10px' }}>
                <div className="d-flex align-items-center flex-row justify-content-center gap-4 mt-3">
                  <div className="choose-container" style={{ background: 'white' }}>
                    <FontAwesomeIcon
                      icon={faChevronCircleLeft}
                      color="#F6821F"
                    className=" backIcon"
                    onClick={onPrevious}
                    />
                  </div>
                  <div className="choose-container" style={{ background: '#F6821F' }}>
                    <p onClick={handleYesClick}>{t('Yes')}</p>
                  </div>
                  <div className="choose-container" style={{ background: '#F6821F' }}>
                    <p onClick={handleNoClick}>{t('No')}</p>
                  </div>
                </div>
              </div>
            </>
          ) : damaged && !noDamaged ? (
            <div className="d-flex align-items-center justify-content-center flex-column">
              <div className="image-preview-grid">
                {selectedImages.map((image, index) => (
                  <div key={index} className="image-preview-item">
                    <img
                      src={image}
                      alt={`Selected ${index + 1}`}
                      className="preview-image"
                    />
                    <button
                      className="remove-image-btn"
                      onClick={(e) => {
                        e.preventDefault();
                        const updatedImages = selectedImages.filter((_, i) => i !== index);
                        setSelectedImages(updatedImages);
                        setDamagedPicture(updatedImages);
                      }}
                    >
                      ✖
                    </button>
                  </div>
                ))}
              </div>
              {showSelectImage && (
                <Modal onClose={() => setShowSelectImage(false)}>
                  <SelectImage
                    imageUri={handleImageSelection}
                    showWebcam={() => {
                      setShowSelectImage(false);
                      setShowWebcam(true);
                    }}
                    closeModal={() => setShowSelectImage(false)} isShowModal={showSelectImage} />
                </Modal>
              )}
              {showWebcam && (
                <div style={{ width: '100%', height: '400px' }}>
                  <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    width="100%"
                    height="400px"
                    videoConstraints={{ facingMode: 'user' }}
                  />
                </div>
              )}
              <div className="d-flex mt-3 clock1">
                <Button
                  onButtonClick={onPrevious}
                  title={t('Previous')}
                  backgroundColor={'#F6821F'}
                  color="white"
                />
                {showWebcam ? (
                  <Button
                    onButtonClick={handleCapture}
                    title={t('Capture')}
                    backgroundColor="#F6821F"
                    color="white"
                  />
                ) : (
                  <Button
                    onButtonClick={() => {
                      setShowWebcam(false);
                      setShowSelectImage(true);
                    }}
                    title={t('Select_Image')}
                    backgroundColor="#F6821F"
                    color="white"
                  />
                )}
                <Button
                  onButtonClick={onNextYes}
                  title={t('Next')}
                  backgroundColor={noDamaged || (damaged && selectedImages.length > 0) ? '#F6821F' : '#ccc'}
                  color="white"
                  disabled={!noDamaged && selectedImages.length === 0}
                />
              </div>
            </div>
          ) : null}
        </div>
      </form>
    </div>
  );
};

export default StepDamageQuestion;
