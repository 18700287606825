import React, { useState } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import Button from './Button';

interface VehicleModalProps {
    isOpen: boolean;
    onClose: () => void;
    vehicleData: any[];
    onSubmit: (selectedVehicle: any) => void;
    t: (key: string) => string;
}

const VehicleModal: React.FC<VehicleModalProps> = ({
    isOpen,
    onClose,
    vehicleData,
    onSubmit,
    t,
}) => {
    const [selectedVehicleId, setSelectedVehicleId] = useState<string | null>(null);
    const [searchQuery, setSearchQuery] = useState('');

    const handleVehicleSelect = (vehicle: any) => {
        setSelectedVehicleId(vehicle.carId);
    };

  const handleSubmit = () => {
    const selectedVehicle = vehicleData.find(vehicle => vehicle.carId === selectedVehicleId);
    if (selectedVehicle) {
        localStorage.setItem('selectedVehicle', JSON.stringify(selectedVehicle));
        onSubmit(selectedVehicle);
        onClose();
    }
};

    const filteredVehicles = vehicleData.filter(vehicle =>
        vehicle.carNumber.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            style={{
                content: {
                    height: 445,
                    width: '65%',
                    margin: 'auto',
                    padding: 10,
                },
            }}
        >
            <div className="flex flex-col h-full">
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <h5 className="text-lg font-medium">{t('select_vehicle')}</h5>
                    <FontAwesomeIcon
                        icon={faTimes}
                        className="text-xl cursor-pointer text-emerald-700"
                        onClick={onClose}
                    />
                </div>

                <input
                    type="text"
                    placeholder={t('search_vehicles')}
                    className="w-full p-2 mb-3 border rounded"
                    value={searchQuery}
                    style={{ width: '100%' }}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />

                <div className="flex-1 overflow-y-auto">
                    <ul className="divide-y divide-gray-200" style={{ height: "242px" }}>
                        {filteredVehicles.map((vehicle) => (
                            <li
                                key={vehicle.carId}
                                className={`d-flex justify-content-between align-items-center ${selectedVehicleId === vehicle.carId ? 'bg-emerald-50' : ''
                                    }`}
                                onClick={() => handleVehicleSelect(vehicle)}
                                style={{ cursor: 'pointer', padding: '10px', border: '1px solid #ccc' }}

                            >
                                <span className="flex-1">{vehicle.carNumber}</span>
                                {selectedVehicleId === vehicle.carId && (
                                    <FontAwesomeIcon
                                        icon={faCheck}
                                        className="text-emerald-600"
                                    />
                                )}
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="d-flex align-items-center justify-content-center mt-4">
                    <Button
                        backgroundColor={selectedVehicleId ? "green" : "gray"}
                        border={selectedVehicleId ? "2px solid green" : "2px solid gray"}
                        disabled={!selectedVehicleId}
                        onButtonClick={handleSubmit}
                        title={t('Submit')}
                        color='white'
                        width="110px"
                    />
                </div>
            </div>
        </Modal>
    );
};

export default VehicleModal;