import React, { useState } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import Button from './Button';

interface SelecterModalProps {
    isOpen: boolean;
    onClose: () => void;
    onPressIcon: () => void;
    heading: string;
    usersData: { user_id: number; firstname: string; lastname: string }[];
    t: (key: string) => string;
    onSubmit: (selectedUser: any) => void;
}

const SelecterModal: React.FC<SelecterModalProps> = ({
    isOpen,
    onClose,
    onPressIcon,
    heading,
    usersData,
    t,
    onSubmit,
}) => {
    const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
    const [searchQuery, setSearchQuery] = useState('');

    const handleUserSelect = (userId: number) => {
        setSelectedUserId(userId);

    };

    const handleSubmit = () => {
        const selectedUser = usersData.find(user => user.user_id === selectedUserId);
        if (selectedUser) {
            onSubmit(selectedUser);
            onClose();
        }
    };

    const filteredUsers = usersData.filter(user =>
        `${user.firstname} ${user.lastname}`.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            style={{
                content: {
                    height: 445,
                    width: 'auto',
                    margin: 'auto',
                    padding: 10,
                },
            }}
        >
            <div className="flex flex-col h-full">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <p className=" font-medium" style={{fontSize:'16px'}}>{heading}</p>
                    <FontAwesomeIcon
                        icon={faTimes}
                        className="text-xl cursor-pointer text-emerald-700"
                        onClick={onPressIcon}
                    />
                </div>

                <input
                    type="text"
                    placeholder={t('search_user')}
                    className="w-full p-2 mb-3 border rounded"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    style={{ width: '100%' }}
                />

                <div className="flex-1 overflow-y-auto">
                    <ul className="divide-y divide-gray ml-3" style={{ height: "242px" }}>
                        {filteredUsers.map((user) => (
                            <li
                                key={user.user_id}
                                className={`d-flex justify-content-between align-items-center `}
                                onClick={() => handleUserSelect(user.user_id)}
                                style={{ cursor: 'pointer', padding: '10px', border: '1px solid #ccc' }}
                            >
                                <span className="flex-1 ml-5">
                                    {`${user.firstname} ${user.lastname}`}
                                </span>
                                {selectedUserId === user.user_id && (
                                    <FontAwesomeIcon
                                        icon={faCheck}
                                        className="text-emerald"
                                    />
                                )}
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="d-flex align-items-center justify-content-center mt-4">
                    <Button
                        backgroundColor={selectedUserId ? "green" : "gray"}
                        color="white"
                        border={selectedUserId ? "2px solid green" : "2px solid gray"}
                        onButtonClick={handleSubmit}
                        title={t('Submit')}
                        width="110px"
                        disabled={!selectedUserId}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default SelecterModal;