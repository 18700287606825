import {
  faTimes
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Loader from 'react-js-loader'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { useLocation, useNavigate } from 'react-router-dom'
import { AuthDetails } from '../libs/authDetails'
import GarbageForm from '../pages/GarbageForm'
import LicenseForm from '../pages/LicenseForm'
import SubmitForm from '../pages/SubmitForm'
import { createTrip } from '../services/ApiServices'
import { CurrentFormData, driverCardData, DriverDetailFormData, fqnCardDetails, GarbageFormData, licenseFormData, LoactionFormData, mileageData, StartAndPauseData, SubmitFormData, TripAndVehicleTypeData } from '../types/steppertype'
import Button from './Button'
import DigitalSignature from './DigitalSignater'
import DriverDetail from './DriverDetail'
import Header from './Header'
import Modal from './Modal'
import StartAndPause from './StartAndPause'
import TripAndVehicleType from './TripAndVehicleType'
import WelcomeStep from './WelcomeStep'
import { RxCross2 } from "react-icons/rx";

interface FormData {
  startandPauseData: StartAndPauseData
  currentTimeData: CurrentFormData
  driverDetailFormData: DriverDetailFormData
  loactionFormData: LoactionFormData
  tripAndVehicleTypeData: TripAndVehicleTypeData
  garbageFormData: GarbageFormData
  licenseFormData: licenseFormData
  driverCardData: driverCardData
  fqnCardDetails: fqnCardDetails
  mileageData: mileageData
  submitFormData: SubmitFormData
}
const StepperForm: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    startandPauseData: {},
    currentTimeData: {},
    driverDetailFormData: {},
    loactionFormData: {},
    tripAndVehicleTypeData: {},
    garbageFormData: {},
    licenseFormData: {},
    driverCardData: {},
    fqnCardDetails: {},
    mileageData: {},
    submitFormData: { signature: null }
  })
  const { t } = useTranslation()
  const [currentStep, setCurrentStep] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [validationError, setValidationError] = useState(false)
  const { token, is_admin, userId: authUserId } = AuthDetails()
  const [iconModal, setIconModal] = useState(false)
  const [buttonDisabled, setIsButtonDisabled] = useState(true)
  const [loading, setLoading] = useState(false)
  const [selectedDriver, setSelectedDriver] = useState<string>('');
  const [dropdownValue, setDropdownValue] = useState('');

  const [subDriver, setSubDriver] = useState<string>('');

  const navigate = useNavigate()
  const data11 = JSON.parse(
    localStorage.getItem('tripAndVehicleTypeData') || '{}'
  )
  const [selection, setSelection] = useState<string>('');
  const location = useLocation();
  const [isShowModal, setIsShowModal] = useState(false);
  const [isModal, setShowModal] = useState(true);

  useEffect(() => {
    if (location.state && location.state.showModal) {
      setShowModal(true);
    }
  }, [location.state]);

  const UserId = is_admin === 1 ? data11.user_id || '' : authUserId

  const stepMapping: Record<string, number[]> = {
    'Driver': [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    'Loader': [1, 2, 3, 4, 5, 6, 12],
    'Machine Operator': is_admin === 1 ? [1, 2, 3, 5, 12] : [1, 2, 3, 12],
    'Locksmith': [1, 2, 3, 5, 12],
    "Workshop Worker": is_admin === 1  ? [1, 2, 3, 5, 12] : [1, 2, 3, 12],
    "Yard Worker": is_admin === 1  ?  [1, 2, 3, 5, 12] : [1, 2, 3, 12]
  };
  useEffect(() => {
    const storedStep = localStorage.getItem('currentStep')
    if (storedStep) {
      setCurrentStep(parseInt(storedStep, 10));
    } else {
      setCurrentStep(stepMapping[selectedDriver]?.[0] || 1);
    }
  }, [formData.submitFormData, selectedDriver])
  useEffect(() => {
    localStorage.removeItem('currentStep');
    localStorage.removeItem('selectedDriver');
    setCurrentStep(1);
  }, []);
  const handleSubmit = (data: FormData, step: string) => {
    setFormData((prevData) => ({
      ...prevData,
      [step]: data
    }))
    localStorage.setItem(step, JSON.stringify(data))
    handleNext()
  }
  useEffect(() => {
    const storedDriver = localStorage.getItem('selectedDriver');
    if (storedDriver) {
      setSelectedDriver(storedDriver);
    }

    const storedStep = localStorage.getItem('currentStep');
    if (storedStep) {
      setCurrentStep(parseInt(storedStep, 10));
    }
  }, []);
  useEffect(() => {
    window.addEventListener("popstate", function (event) {
      navigate("/dashboard")
    });
  })
  const handleNext = () => {
    setCurrentStep(prevStep => {
      const currentStepIndex = stepMapping[selectedDriver]?.indexOf(prevStep) || 0;
      const nextStep = stepMapping[selectedDriver]?.[currentStepIndex + 1] || prevStep;
      localStorage.setItem('currentStep', nextStep.toString());
      localStorage.setItem('selectedDriver', selectedDriver);
      return nextStep;
    });
  };

  useEffect(() => {
    if (formData.submitFormData.signature) {
      enableSubmit()
    }
  }, [formData.submitFormData.signature])

  const handlePrev = () => {
    setCurrentStep(prevStep => {
      const prevStepIndex = stepMapping[selectedDriver]?.indexOf(prevStep) - 1;
      const nextStep = stepMapping[selectedDriver]?.[prevStepIndex] || 1;
      localStorage.setItem('currentStep', nextStep.toString());
      return nextStep;
    });
  }
  const handleSignatureSaved = async (
    signatureDataURL: string | null,
  ) => {
    await setFormData((prevData) => ({
      ...prevData,
      submitFormData: {
        ...prevData.submitFormData,
        signature: signatureDataURL
      }
    }))
    await enableSubmit()
  }
  const userSelected = localStorage.getItem("selectedUser") || ""
  const myDriver = localStorage.getItem("isSelectedDriver") || ""
  let Signatue_image: any = localStorage.getItem("signatureImage")
  const enableSubmit = () => {
    const {
      fqnCardDetails,
      driverCardData,
      driverDetailFormData,
      loactionFormData,
      garbageFormData,
      licenseFormData,
      mileageData,
      submitFormData,
      tripAndVehicleTypeData,
      startandPauseData,
      currentTimeData
    } = formData;

    const isEmptyObject = (obj: StartAndPauseData | CurrentFormData | DriverDetailFormData | LoactionFormData | TripAndVehicleTypeData | GarbageFormData | licenseFormData | driverCardData | fqnCardDetails | mileageData | null | undefined) => {
      return obj === null || obj === undefined || Object.keys(obj).length === 0;
    };
    const hasEmptySubmitFormData = Object.values(submitFormData).some((value) => !value);

    if (selectedDriver === 'Driver') {
      if (
        isEmptyObject(fqnCardDetails) ||
        isEmptyObject(driverCardData) ||
        isEmptyObject(driverDetailFormData) ||
        isEmptyObject(loactionFormData) ||
        isEmptyObject(garbageFormData) ||
        isEmptyObject(licenseFormData) ||
        isEmptyObject(mileageData) ||
        // isEmptyObject(tripAndVehicleTypeData) ||
        isEmptyObject(startandPauseData) ||
        isEmptyObject(currentTimeData)
        // hasEmptySubmitFormData
      ) {
        setIsButtonDisabled(true);
      } else {
        setIsButtonDisabled(false);
      }
    } else if (selectedDriver === 'Machine Operator') {
      if (
        isEmptyObject(startandPauseData) ||
        isEmptyObject(currentTimeData)
        // isEmptyObject(tripAndVehicleTypeData)
        // hasEmptySubmitFormData
      ) {
        setIsButtonDisabled(true);
      } else {
        setIsButtonDisabled(false);
      }
    } else if (selectedDriver === 'Loader') {
      if (
        isEmptyObject(tripAndVehicleTypeData) ||
        isEmptyObject(startandPauseData) ||
        isEmptyObject(currentTimeData) ||
        isEmptyObject(driverDetailFormData) ||
        isEmptyObject(garbageFormData)
        // hasEmptySubmitFormData
      ) {
        setIsButtonDisabled(true);
      } else {
        setIsButtonDisabled(false);
      }
    } else if (
      selection === 'Yard Worker' ||
      selection === 'Workshop Worker' ||
      selection === 'Locksmith'
    ) {
      if (isEmptyObject(startandPauseData) ||
        isEmptyObject(currentTimeData)
      ) {
        setIsButtonDisabled(true);
      } else {
        setIsButtonDisabled(false);
      }
    } else {
      setIsButtonDisabled(true);
    }
  };
  const handleFinalSubmit = async () => {
    try {
      setIsLoading(true)
      const {
        startandPauseData,
        currentTimeData,
        driverDetailFormData,
        loactionFormData,
        tripAndVehicleTypeData,
        garbageFormData,
        licenseFormData,
        driverCardData,
        fqnCardDetails,
        mileageData,
        submitFormData
      } = formData
      if (!submitFormData.signature) {
        setValidationError(true)
        setIsLoading(false)
        return
      }
      const storedData = localStorage.getItem('user')
      const finalCoDriver = tripAndVehicleTypeData?.co_driver || "";

            const parsedData = storedData ? JSON.parse(storedData) : null
      const combinedData = {
        current_time: currentTimeData?.current_time || '',
        start_time:
          startandPauseData?.start_time + ' ' + currentTimeData?.current_time,
        car_number: driverDetailFormData?.carNumber || '',
        car_id: driverDetailFormData?.carId || '',
        cost_center_id: tripAndVehicleTypeData?.cost_center_id || '',
        pauseTime: startandPauseData?.pauseTime || [],
        location: loactionFormData?.location || '',
        postal_code: loactionFormData?.postal_code || '',
        co_driver: finalCoDriver,
        garbageType: garbageFormData?.garbageType || "",
        licenseExpiry: licenseFormData?.licenseExpiry || '',
        licenseImage: licenseFormData?.licenseImage || '',
        // driverCard: driverCardData?.driverCard || '',
        cardExpiryDate: driverCardData?.cardExpiryDate || '',
        // fqnCard: fqnCardDetails?.fqnCard || '',
        fqnExpirationDate: fqnCardDetails?.fqnExpirationDate || '',
        mileageData: mileageData?.start_mileage || '',
        signatureData: submitFormData?.signature || '',
        userId: tripAndVehicleTypeData?.userId || ''
      }
      let passengerName = selectedDriver === "Driver" ? userSelected : ""
      let driver = selectedDriver !== "Driver" && is_admin !== 1 ? myDriver : is_admin === 1 && selectedDriver !== "Driver" ? userSelected : ""
      console.log("Final Co-Driver Data:", finalCoDriver);
      await createTrip(
        combinedData.start_time,
        combinedData.current_time,
        combinedData.car_number,
        combinedData.car_id,
        combinedData.cost_center_id,
        combinedData.location,
        combinedData.postal_code,
        combinedData.garbageType,
        combinedData.licenseExpiry,
        combinedData.licenseImage,
        // combinedData.driverCard,
        combinedData.cardExpiryDate,
        // combinedData.fqnCard,
        combinedData.fqnExpirationDate,
        combinedData.mileageData,
        combinedData.signatureData,
        selectedDriver,
        dropdownValue,
        passengerName,
        finalCoDriver,
        driver,
        UserId,
        token,

      )
      setFormData({
        garbageFormData: {},
        startandPauseData: {},
        currentTimeData: {},
        driverDetailFormData: {},
        loactionFormData: {},
        tripAndVehicleTypeData: {},
        licenseFormData: {},
        driverCardData: {},
        fqnCardDetails: {},
        mileageData: {},
        submitFormData: { signature: null }
      })
      localStorage.removeItem('startandPauseData')
      localStorage.removeItem('errorMessageInput');
      localStorage.removeItem('errorMessageInput1');
      localStorage.removeItem('errorMessageInput2');
      localStorage.removeItem('driverDetailFormData')
      localStorage.removeItem('loactionFormData')
      localStorage.removeItem('OptionalPassenger')
      localStorage.removeItem('tripAndVehicleTypeData')
      localStorage.removeItem('licenseFormData')
      localStorage.removeItem('garbageFormData')
      localStorage.removeItem('signatureDataURL')
      localStorage.removeItem('currentStep')
      localStorage.removeItem('mileageData')
      localStorage.removeItem('co_driver')
      localStorage.removeItem('isdriver')
      localStorage.removeItem("signatureImage")
      localStorage.removeItem('isCheckboxChecked')
      localStorage.removeItem("isSelectedDriver")
      localStorage.removeItem('fqnCardDetails')
      localStorage.removeItem('driverCardData')
      localStorage.removeItem('selectedUser')
      setIsLoading(false)
      navigate('/dashboard')
    } catch (error) {
      console.error('Error creating trip:', error)
    }
  }

  const handleComponentChange = (index: number) => {
    const adjustedIndex = index + 1;
    if (selectedDriver === 'Machine Operator' && adjustedIndex === 4) {
      setCurrentStep(5);
    } else if (selectedDriver === 'Loader' && adjustedIndex === 7) {
      setCurrentStep(12);
    } else {
      setCurrentStep(adjustedIndex);
    }

  };
  const signatureArea = () => {
    return (
      <>
        {Signatue_image === null && <div className="d-flex align-items-center justify-content-between">
          <h6>{t('Digital_Signature')}</h6>
          {selectedDriver === "Driver" ? <FontAwesomeIcon
            icon={faTimes}
            className="crossIcon"
            color="#05653F"
            onClick={() => setIconModal(false)}
          /> : null}
        </div>}
        <DigitalSignature
          onSignatureSaved={handleSignatureSaved}
          validationError={validationError}
          isAdmin={is_admin}
          onclick={() => setIconModal(false)}
          selectedDriver={selectedDriver}
          setValidationError={setValidationError}
        />
        <div className="d-flex align-items-center justify-content-center  mb-1 mt-3 clock">

          {isLoading ? (
            <Loader
              type="bubble-top"
              bgColor={'#05653F'}
              color={'#05653F'}
              size={50}
            />
          ) : (
            <Button
              title={selection === 'Yard Worker' || selection === 'Workshop Worker' || selection === 'Locksmith' || selection === 'Machine Operator' ? t("start_work_now") : t('Start_now')}
              onButtonClick={handleFinalSubmit}

              color="white"
              disabled={buttonDisabled}
              style={
                buttonDisabled
                  ? {
                    pointerEvents: 'none',
                    opacity: 0.5,
                    background: 'grey'
                  }
                  : {
                    backgroundColor: '#05653F'
                  }
              }
            />
          )}
        </div>
      </>
    )
  }
  const handleRadioChange = (event: { target: { value: any } }) => {
    const newValue = event.target.value;
    setSelection(newValue)
    setDropdownValue('');
  };
  const handleOK = () => {
    setSelectedDriver(selection);
    localStorage.removeItem('selectedDriver');
    setShowModal(false)
  }
  const handleRadioChangeNew = (e: { target: { value: React.SetStateAction<string> } }) => {
    setSelection(e.target.value);
    setDropdownValue('');
  };
  const handleDropdownChange = (e: { target: { value: React.SetStateAction<string> } }) => {
    setDropdownValue(e.target.value);
  };
  return (
    <>
      {isModal && (
        <Modal content={true}>
          <div className="userHeading" >
            <h4 className='mt-2'>{t("WhatTypeDriver")}</h4>
            <RxCross2
              onClick={() => navigate('/dashboard')}
              className="icon close-icon"
              color="white"
            />
          </div>
          <div className='d-flex align-items-center justify-content-between px-4 mt-3' >
            <p style={{ fontSize: '18px' }}>{t("Driver")}</p>
            <input
              type="radio"
              name="driverType"
              value="Driver"
              checked={selection === 'Driver'}
              onChange={handleRadioChange}
              style={{ height: '20px', width: '20px', marginBottom: "5px" }}
            />
          </div>
          <div className='d-flex align-items-center justify-content-between mt-2  px-4 ' >
            <p style={{ fontSize: '18px' }}>{t("Loader")}</p>
            <input
              type="radio"
              name="driverType"
              value="Loader"
              checked={selection === 'Loader'}
              onChange={handleRadioChange}
              style={{ height: '20px', width: '20px', marginBottom: "5px" }}
            />
          </div>
          <div className='d-flex align-items-center justify-content-between mt-2 px-4 mb-1'>
            <p style={{ fontSize: '18px' }}>{t("MachineOperator")}</p>
            <input
              type="radio"
              name="driverType"
              value="Machine Operator"
              checked={selection === 'Machine Operator'}
              onChange={handleRadioChange}
              style={{ height: '20px', width: '20px', marginBottom: "5px" }}
            />
          </div>
          <div className='d-flex align-items-center justify-content-between mt-2 px-4 mb-1'>
            <p style={{ fontSize: '18px' }}>{t("locksmith")}</p>
            <input
              type="radio"
              name="driverType"
              value="Locksmith"
              checked={selection === 'Locksmith'}
              onChange={handleRadioChangeNew}
              style={{ height: '20px', width: '20px', marginBottom: "5px" }}
            />
          </div>
          <div className='d-flex align-items-center justify-content-between mt-2 px-4 mb-1'>
            <p style={{ fontSize: '18px' }}>{t("yard_worker")}</p>
            <input
              type="radio"
              name="driverType"
              value="Yard Worker"
              checked={selection === 'Yard Worker'}
              onChange={handleRadioChangeNew}
              style={{ height: '20px', width: '20px', marginBottom: "5px" }}
            />
          </div>
          <div className='d-flex align-items-center justify-content-between mt-2 px-4 mb-1'>
            <p style={{ fontSize: '18px' }}>{t("workshop_worke")}</p>
            <input
              type="radio"
              name="driverType"
              value="Workshop Worker"
              checked={selection === 'Workshop Worker'}
              onChange={handleRadioChangeNew}
              style={{ height: '20px', width: '20px', marginBottom: "5px" }}
            />
          </div>
          {(selection === 'Yard Worker' || selection === 'Workshop Worker' || selection === 'Locksmith' || selection === 'Driver' || selection === 'Machine Operator' || selection === 'Loader') && (
            <div className='mt-3 d-flex flex-column  justify-content-between'>
              {/* <p  style={{ marginLeft: '12px' }}>{t("select_option")}</p> */}
              <select
                id="dropdown"
                value={dropdownValue}
                className='inputform'
                onChange={handleDropdownChange}
                style={{ marginLeft: '10px', marginRight: '10px' }}
              >
                <option value="" disabled>{t("choose_option")}</option>
                <option value='Temporary Worker'>{t("temporary_worker")}</option>
                {/* <option value='Holiday Worker'>{t("holiday_worker")}</option> */}
                <option value='Seasonal Worker'>{t("seasonal_worker")}</option>
              </select>
            </div>
          )}
          <div className='d-flex align-items-center justify-content-center mt-4 mb-3 px-5'>
            <Button
              title={t('Submit')}
              onButtonClick={handleOK}
              disabled={!selection}
              style={
                !selection
                  ? {
                    pointerEvents: 'none',
                    opacity: 0.5,
                    background: 'grey'
                  }
                  : {
                    backgroundColor: '#05653F',
                    color: 'white'
                  }
              }
            />
          </div>

        </Modal>
      )}
      <Header title={t('Digital_Driving')} />

      <div className='stepcount'>
        <span style={{ fontSize: "18px", fontWeight: "bold", color: "#05653F" }}>
          {t("Step")}{" "}
          {selectedDriver === "Loader"
  ? `${currentStep === 12 ? `7` : currentStep}/7`
  : selectedDriver === "Machine Operator" && is_admin === 1
    ? `${currentStep === 5 ? `4` : currentStep === 12 ? "5" : currentStep}/5`
    : selectedDriver === "Machine Operator" && is_admin !== 1
      ? `${currentStep === 12 ? "4" : currentStep}/4`
      : (selectedDriver === "Yard Worker" ||
         selectedDriver === "Locksmith" ||
         selectedDriver === "Workshop Worker")
        ? is_admin === 1
          ? `${currentStep === 5 ? `4` : currentStep === 12 ? "5" : currentStep}/5`
          : `${currentStep === 3 ? `3` : currentStep === 12 ? "4" : currentStep}/4`
        : `${currentStep}/12`}

        </span>      </div>
      <Carousel
        showStatus={false}
        className="carousel"
        preventMovementUntilSwipeScrollTolerance={true}
        showThumbs={false}
        showIndicators={false}
        verticalSwipe="standard"
        dynamicHeight={false}
        selectedItem={currentStep - 1}
        onChange={handleComponentChange}
        swipeable={false}
        renderArrowPrev={() => null}
        renderArrowNext={() => null}>
        <div className="d-flex align-items-center justify-content-center m-auto height">
          {currentStep === 1 && (selectedDriver === 'Driver' || selectedDriver === 'Loader' || selectedDriver === 'Machine Operator' || selectedDriver === 'Yard Worker' || selectedDriver === 'Workshop Worker' || selectedDriver === 'Locksmith') &&
            <WelcomeStep onNext={handleNext} selectedUserType={selectedDriver} />
          }
        </div>
        <>
          {currentStep === 2 && ((selectedDriver === 'Driver' || selectedDriver === 'Loader' || selectedDriver === 'Machine Operator' || selectedDriver === 'Yard Worker' || selectedDriver === 'Workshop Worker' || selectedDriver === 'Locksmith')) && (
            <StartAndPause
              onNext={(data: FormData) => handleSubmit(data, 'startandPauseData')}
              onPrevious={handlePrev}
              initialFormData={formData.startandPauseData}
              isStartTime={true}
              data={JSON.parse(
                localStorage.getItem('startandPauseData') || '{}'
              )}
            />
          )}
        </>
        <>
          {currentStep === 3 && (selectedDriver === 'Driver' || selectedDriver === 'Loader' || selectedDriver === 'Machine Operator' || selectedDriver === 'Yard Worker' || selectedDriver === 'Workshop Worker' || selectedDriver === 'Locksmith') && (
            <StartAndPause
              onNext={(data: FormData) => handleSubmit(data, 'currentTimeData')}
              onPrevious={handlePrev}
              initialFormData={formData.currentTimeData}
              data={JSON.parse(localStorage.getItem('currentTimeData') || '{}')}
              isStartTime={false} />
          )}
        </>
        <>
          {currentStep === 4 && (selectedDriver === 'Driver' || selectedDriver === 'Loader') && (
            <GarbageForm
              onNext={(data: FormData) => handleSubmit(data, 'garbageFormData')}
              onPrevious={handlePrev}
              data={JSON.parse(localStorage.getItem('garbageFormData') || '{}')}
              showModal={isShowModal}
              onSeeMore={() => setIsShowModal(true)}
              onClose={() => setIsShowModal(false)}
            />
          )}
        </>
        <>
        {currentStep === 5 && (
  (selectedDriver === 'Driver' ||
   selectedDriver === 'Loader' ||
   selectedDriver === 'Locksmith' ||
   selectedDriver === 'Machine Operator' && is_admin === 1 ||
   (is_admin === 1 && (selectedDriver === 'Yard Worker' || selectedDriver === 'Workshop Worker'))
  ) && (
    <TripAndVehicleType
      onNext={(data: FormData) => handleSubmit(data, 'tripAndVehicleTypeData')}
      tripAndVehicleTypeData={true}
      onPrevious={handlePrev}
      data={JSON.parse(localStorage.getItem('tripAndVehicleTypeData') || '{}')}
      mileageData={false}
    />
  )
)}

        </>
        <>
          {currentStep === 6 && (selectedDriver === 'Driver' || selectedDriver === 'Loader') && (
            <DriverDetail
              onNext={(data: FormData) => handleSubmit(data, 'driverDetailFormData')}
              onPrevious={handlePrev}
              initialFormData={formData.driverDetailFormData}
              isLocation={false}
              data={JSON.parse(
                localStorage.getItem('driverDetailFormData') || '{}'
              )}
            />
          )}
        </>
        <>
          {currentStep === 7 && (selectedDriver === 'Driver') && (
            <DriverDetail
              onNext={(data: FormData) => handleSubmit(data, 'loactionFormData')}
              onPrevious={handlePrev}
              initialFormData={formData.loactionFormData}
              isLocation={true}
              data={JSON.parse(
                localStorage.getItem('loactionFormData') || '{}'
              )}
            />
          )}
        </>
        <>
          {currentStep === 8 && (selectedDriver === 'Driver') && (
            <TripAndVehicleType
              onNext={(data: FormData) => handleSubmit(data, 'mileageData')}
              mileageData={true}
              onPrevious={handlePrev}
              data={JSON.parse(localStorage.getItem('mileageData') || '{}')}
            />
          )}
        </>
        <>
          {currentStep === 9 && (selectedDriver === 'Driver') && (
            <LicenseForm
              onNext={(data: FormData) => handleSubmit(data, 'licenseFormData')}
              onPrevious={handlePrev}

              licenseForm={true}
              loading={setLoading}
              data={JSON.parse(localStorage.getItem('licenseFormData') || '{}')}
            />
          )}
        </>
        <>
          {currentStep === 10 && (selectedDriver === 'Driver') && (
            <LicenseForm
              onNext={(data: FormData) => handleSubmit(data, 'driverCardData')}
              onPrevious={handlePrev}
              driverCard={true}
              loading={setLoading}
              data={JSON.parse(localStorage.getItem('driverCardData') || '{}')}
              licenseForm={false}
            />
          )}
        </>
        <>
          {currentStep === 11 && (selectedDriver === 'Driver') && (
            <LicenseForm
              onNext={(data: FormData) => handleSubmit(data, 'fqnCardDetails')}
              onPrevious={handlePrev}
              fqnCard={true}
              loading={setLoading}
              data={JSON.parse(localStorage.getItem('fqnCardDetails') || '{}')}
              licenseForm={false}
            />
          )}
        </>
        <>
          {currentStep === 12 && (selectedDriver === 'Driver' || selectedDriver === 'Loader' || selectedDriver === 'Machine Operator' || selection === 'Yard Worker' || selection === 'Workshop Worker' || selection === 'Locksmith') && (
            <SubmitForm
              SelectedUser={selectedDriver}
              onNext={handleNext}
              onPrevious={handlePrev}
              signatureArea={signatureArea()}
              onSignatureSaved={handleSignatureSaved}
              handleFinalSubmit={handleFinalSubmit}
              validationError={validationError}
              setValidationError={setValidationError}
              swiperIndex={currentStep}
              onClick={() => setIconModal(true)}
            />
          )}
        </>
      </Carousel>
      {iconModal && (
        <Modal onClose={() => setIconModal(false)} contentWidth={'30%'}>
          {signatureArea()}
        </Modal>)}

      {loading && (
        <div className="overlay">
          <Loader
            type="bubble-top"
            bgColor={'#05653F'}
            color={'#05653F'}
            size={50}
          />
        </div>
      )}
    </>
  )
}

export default StepperForm
