import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import Button from './Button'

const SelectImage = ({
  imageUri,
  closeModal,
  isShowModal,
  isTourEnd,
  showWebcam,
  imgSrc,
}: {
  imageUri: (paths: string[]) => void;
  closeModal: (path: boolean) => void
  isShowModal: boolean
  isTourEnd?: boolean
  showWebcam?:any
  imgSrc?:any
}) => {
  const { t } = useTranslation()

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

const handleImagePicker = () => {
  const input = document.createElement('input');
  input.type = 'file';
  input.accept = 'image/*';
  input.multiple = true;

  input.onchange = (event) => {
    const files = (event.target as HTMLInputElement).files;
    if (files) {
      const imageUrls: string[] = [];
      let filesLoaded = 0;

      Array.from(files).forEach((file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onloadend = () => {
          if (typeof reader.result === 'string') {
            imageUrls.push(reader.result);
          }
          filesLoaded++;

          if (filesLoaded === files.length) {
            imageUri(imageUrls);
            closeModal(false);
          }
        };
      });
    }
  };
  input.click();
};



  return (
    <>
      {isTourEnd ? (
        <>
          <div className="d-flex align-items-center justify-content-end">
            <FontAwesomeIcon
              icon={faTimes}
              className="crossIcon"
              onClick={() => closeModal(false)}
              color="#05653F"
            />
          </div>
          <div className="d-flex align-items-center justify-content-center flex-column clock mt-5">
            <Button
              title={isMobile ? t('Choose_Gallery') : t('Choose_File')}
              backgroundColor={'white'}
              border={'2px solid #05653F'}
              onButtonClick={handleImagePicker}
              width="160px"
              style={{ paddingLeft: '0px', paddingRight: '0px' }}
            />
            <Button
              backgroundColor={'white'}
              border={'2px solid #05653F'}
              onButtonClick={showWebcam}
              title={t('Capture_Photo')}
              width="160px"
            />
          </div>

        </>
      ) : (
        <>
          <Modal
            isOpen={isShowModal}
            onRequestClose={() => closeModal(false)}
            style={{
              content: {
                height: 240,
                width: 250,
                margin: 'auto',
                padding: 10
              }
            }}>
            <div className="d-flex align-items-center justify-content-end">
              <FontAwesomeIcon
                icon={faTimes}
                className="crossIcon"
                onClick={() => closeModal(false)}
                color="#05653F"
              />
            </div>
            <div className="d-flex align-items-center justify-content-center flex-column clock mt-5">
              <Button
                title={isMobile ? t('Choose_Gallery') : t('Choose_File')}
                backgroundColor={'white'}
                border={'2px solid #05653F'}
                onButtonClick={handleImagePicker}
                width="160px"
                style={{ paddingLeft: '0px', paddingRight: '0px' }}
              />
              <Button
                backgroundColor={'white'}
                border={'2px solid #05653F'}
                onButtonClick={showWebcam}
                title={t('Capture_Photo')}
                width="160px"
              />
            </div>

          </Modal>


        </>
      )}
    </>
  )
}

export default SelectImage
