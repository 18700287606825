import { faChevronCircleLeft, faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface AccidentReportProps {
  description: string;
  onDescriptionChange: (newDescription: string) => void;
  onNext?: any;
  onPrevious: () => void;
  errorDes:string
}

const AccidentReport: React.FC<AccidentReportProps> = ({
  description,
  onDescriptionChange,
  onNext,
  onPrevious,
  errorDes
}) => {
  const { t } = useTranslation();

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onDescriptionChange(event.target.value);
  };

  return (
    <div className="d-flex align-items-center justify-content-center m-auto height">
      <form className="main-container">
        <div className="userHeading" style={{ background: '#F6821F' }}>
          <h2 className="textcolor">{t('accident_dam')}</h2>
        </div>
        <div className="p-3">
          <p className="mb-2">{t('description_label')}</p>
          <textarea
            placeholder={t('description')}
            value={description}
            onChange={handleDescriptionChange}
            className="form-control"
          />
           {errorDes && <span className="error-message"   style={{
                  minHeight: '22px',
                  display: 'block',
                  color: 'red'
                }}>{errorDes}</span>}
          <p>{t('accident_note')}</p>
        </div>
        <div
          className="d-flex align-items-center justify-content-center mb-3"
          style={{ gap: 50 }}
        >
          <div
            className="d-flex justify-content-center flex-row align-items-center gap-1"
            onClick={() => onPrevious()}
            style={{ cursor: 'pointer', color: 'rgb(236, 88, 0)', fontSize: '20px' }}
          >
            <FontAwesomeIcon
              icon={faChevronCircleLeft}
              color="rgb(236, 88, 0)"
              className="backIcon"
            />
            <h6>{t('back')}</h6>
          </div>
          <div
            className="d-flex justify-content-center flex-row align-items-center gap-1"
            onClick={onNext}
            style={{ cursor: 'pointer', color: 'rgb(236, 88, 0)', fontSize: '20px' }}
          >
            <FontAwesomeIcon
              icon={faChevronCircleRight}
              color="rgb(236, 88, 0)"
              className="backIcon"

            />
            <h6>{t('further')}</h6>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AccidentReport;
